import {CHANGE_VIEW} from "../actions/types";

const initialState = {
    view: 1,
};

function impactedPatientsReducer(state = initialState, action) {
    // noinspection JSRedundantSwitchStatement
    switch (action.type) {
        case CHANGE_VIEW:
            return Object.assign({}, state, {
                view: action.payload.view
            });
        default:
            return state;
    }
}

export default impactedPatientsReducer;