import logoVyepti from '../images/vyepti_logo_fix.png';
import sustainedIcon from '../images/sustained-background-icon.png';
import disabilityChart from '../images/page12/disability-chart_slide24_korj.png';
import React from "react";
import '../css/SustainedMidas.scss';

function SustainedMidas() {
    return (
        <main className="fade-in react-page-12 background">
            <div className="wrapper">
                <img className="background-icon"
                     src={sustainedIcon}
                     alt="background icon" />
                    <h2 className="title title--margin">
                        >2/3 VYEPTI®-POTILAISTA, JOILLE MIGREENI AIHEUTTI VAIKEAA HAITTAA, RAPORTOIVAT MERKITTÄVÄÄ PARANEMISTA<sup>3*</sup>
                    </h2>
                    <div className="chart-content">
                        <h3 className="subtitle subtitle--margin">
                            HAITAN VAIKEUSASTE MIDAS<sup>†</sup>-PISTEMÄÄRÄN PERUSTEELLA
                        </h3>
                        <div className="chart-container chart-container--center">
                            <div className="chart-image">
                                <img src={disabilityChart} alt="disability chart" />
                            </div>
                        </div>
                        <p className="chart-info">
                            Kuva muokattu lähteestä Kudrow et al. <em>BMC Neurology</em> (2021) 21:126.
                        </p>
                    </div>
                    <ul className="list list--size">
                        <li className="list__item">
                            Lähtötilanteessa noin 40 % potilaista oli diagnosoitu krooninen migreeni sekä lääkepäänsärky<sup>3</sup>
                        </li>
                    </ul>
                    <ul className="footnote-list">
                        <li className="footnote-list__item">
*PREVAIL oli avoin tutkimus, jossa kroonista migreeniä sairastaville annettiin 300 mg:n Vyepti®-annos 12 viikon välein korkeintaan
                            8 kertaa<sup>3</sup> †MIDAS, Migraine Disability Assessment. Haitta päivittäiselle toimintakyvylle arvioitiin
                            käyttämällä Migraine Disability Assessment -arvioinnista saatua kokonaispistemäärää: 0–5  päivää, vaikeusluokka I
                            (vähäinen haitta tai ei haittaa); 6–10 päivää, vaikeusluokka II (lievä haitta); 11–20 päivää,
                            vaikeusluokka III (keskivaikea haitta); vähintään 21 päivää, vaikeusluokka IV (vaikea haitta).
                        </li>
                        <li className="footnote-list__item">
                            <strong>MIDAS</strong>, migraine disability assessment, migreenin aiheuttaman haitan arviointi
                        </li>
                    </ul>
                    <img className="logo" src={logoVyepti}
                         alt="logo vyepti" />
            </div>
        </main>
    )
}

export default SustainedMidas;