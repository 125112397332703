import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {sendTimeStatistics} from "../../actions";

const Timer = () => {
  const dispatch = useDispatch();
  const {page, sessionId} = useSelector(state => state.main);
  const [seconds, setSeconds] = useState(0);
  const [activePage, setActivePage] = useState(page);

  const isActive = true

  if(page !== activePage){
      let payload = {
          activePage,
          page,
          sessionId,
          time: seconds,
      }
      dispatch(sendTimeStatistics(payload))
      setActivePage(page);
      setSeconds(0)
  }



  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  return (
    <div className="timer-app">
      <div className="time">
        {seconds}s
      </div>
    </div>
  );
};

export default Timer;