export const HOMEPAGE = 0;
export const EFFECTIVE_VYEPTI = 1;
export const PATIENT = 2;
export const VYEPTI_MOH = 3;
export const IV = 4;
export const IV_1 = 5;
export const TOLERABILITY = 6;
export const SUMMARY = 7;
export const EFFECTIVE_1 = 8;
export const EFFECTIVE_2 = 9;
export const EFFECTIVE_3 = 10;
export const EFFECTIVE_4 = 11;
export const FAST_1 = 12;
export const FAST_2 = 13;
export const LONG_LASTING_1 = 14;
export const LONG_LASTING_2 = 15;
export const MEDICAL_HEADACHE_1 = 16;
export const MEDICAL_HEADACHE_2 = 17;
export const MEDICAL_HEADACHE_3 = 18;
export const SITEMAP = 19;
export const EXTRA = 20;
export const FINAL_PAGE = 20;
export const BASICS = 30;
export const BASICS_1 = 31;
export const BASICS_2 = 32;
export const BASICS_3 = 33;
export const BASICS_4 = 34;
export const BASICS_5 = 35;
export const ACCESS = 'vyeptiva'
export const DEBUG = 'demo'