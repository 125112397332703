import {useDispatch, useSelector} from "react-redux";
import {closeMohModal} from "../../actions";
import closeIcon from '../../images/teal-close-icon.png'
import graphFrame from '../../images/page3/moh-thresholds-chart_slide7.png'


function MohModal() {
    const {mohModalOpen} = useSelector(state => state.main);
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(closeMohModal());
    };

    return (
        <div id="moh-modal" className={`modal react-page-3 ${mohModalOpen ? 'safety-visible' : ''}`}>
            <div className="modal__card modal__card--moh">
                <button onClick={handleClose} className="modal__close-btn">
                    <img src={closeIcon} alt="teal close icon" />
                </button>
                <h3 className="subtitle">
                    OSUUS POTILAISTA, JOIDEN CM- JA/TAI MOH-DIAGNOSTISET KRITEERIT EIVÄT TÄYTTYNEET
                </h3>
                <div className="chart-container">
                    <div className="graph">

                        <img className="chart-image" src={graphFrame} alt="migraine frequency chart" />
                    </div>
                </div>
                <ul className="footnote-list footnote-list--modal">
                    <li className="footnote-list__item">
                        Muokattu lähteestä Diener H-C et al Headache 2021 Jan;61(1):125-136.
                    </li>
                    <li className="footnote-list__item">
                        <strong>CM</strong>, chronic migraine, krooninen migreeni; <strong>MOH</strong>, medication overuse headache, lääkepäänsärky
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default MohModal;