import logoVyepti from '../images/vyepti_logo_fix.png';
import efficaciousIcon from '../images/efficacious-background-icon.png';
import graphFrame from '../images/page6/graph-frame_slide15_v2.png';
import baseLineArrow from '../images/page6/baseline-arrow_slide15_korj.png';
import React from "react";
import '../css/MddReductions.scss';


function MddReductions() {

    return (
        <main className="fade-in react-page-6 background">
            <div className="wrapper">
                <img className="background-icon"
                     src={efficaciousIcon}
                     alt="background icon" />
                    <h2 className="title title--margin">
                        VYEPTI® ON TEHOKAS MIGREENIN ESTOHOITO, JOKA  VÄHENTÄÄ MERKITSEVÄSTI MIGREENIPÄIVIÄ<sup>2</sup>*
                    </h2>
                    <div className="chart-content">
                        <h3 className="subtitle">
                            Kuukausittaisten migreenipäivien vähenemä, krooninen migreeni<sup>2</sup>
                        </h3>
                        <div className="chart-container">
                            <div className="graph">
                                <div className="graph-container">
                                    <div className="graph-bar teal graph-bar-1">
                                        -7.7
                                        <div>***</div>
                                    </div>
                                    <div className="graph-bar steal graph-bar-2">
                                        -8.2
                                        <div>***</div>
                                    </div>
                                    <div className="graph-bar gray graph-bar-3">
                                        -5.6
                                    </div>
                                </div>
                                <img className="chart-image" src={graphFrame}
                                     alt="migraine frequency chart" />
                            </div>
                            <div className="baseline-arrow">
                                <img src={baseLineArrow} alt="baseline arrow" />
                            </div>
                        </div>
                        <p className="chart-info chart-info--margin">
                            Kuva muokattu lähteestä Lipton et al. <em>Neurology</em> 2020;94;e1365-e1377.
                        </p>
                    </div>
                    <ul className="footnote-list">
                        <li className="footnote-list__item">
                            *PROMISE-2 tutki kroonista migreeniä sairastavia, joiden lähtötilanteen MMD-keskiarvo (SD) oli 16,1 (4,6).
                            Viikoilla 1–12 MMD laski Vyepti®-hoitoa saaneilla enemmän lähtötilanteesta kuin lumelääkettä saaneilla (100 mg, -7,7, p &lt; 0,0001 lumeeseen verrattuna; 300 mg, -8,2, p &lt; 0,0001 lumeeseen verrattuna; lume, -5,6).<sup>2</sup> PROMISE-1 tutki episodista migreeniä sairastavia, joilla MMD-keskiarvo oli kaikissa hoitoryhmissä ~8,6. Sekä Vyepti® 100 mg että 300 mg laskivat episodista migreeniä sairastavien MMD-arvoja  viikoilla 1–12 merkitsevästi lumeeseen verrattuna (100 mg, -3,9, p = 0,0182; 300 mg, -4,3, p = 0,0001; lume, -3,2).<sup>1</sup>

                        </li>
                        <li className="footnote-list__item">
                            <strong>MMD</strong>, monthly migraine day, migreenipäivät kuukaudessa; <strong>SD</strong>, standard deviation, keskihajonta
                        </li>
                    </ul>
                    <img className="logo" src={logoVyepti} alt="logo vyepti" />
            </div>
        </main>
    )
}

export default MddReductions;