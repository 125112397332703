import logoVyepti from '../images/vyepti_logo_fix.png';
import graphFrame from '../images/page15/graph-frame_slide28.png';
import noteIcon from '../images/page15/note-icon.png';
import React from "react";
import '../css/MohAcute.scss';

/*TODO GRAAFI SUOMEKSI*/
function MohAcute() {
    return (
        <main className="fade-in background react-page-15">
            <div className="wrapper">
                {/*<img className="background-icon background-icon--size"*/}
                {/*     src={mohIcon} alt="background icon" />*/}
                    <h2 className="title title--margin">
                        Lääkepäänsärkypotilaiden kohtauslääkkeiden käyttö VÄHENI 50 % ENSIMMÄISEN VYEPTI®-INFUUSION JÄLKEEN<sup>4</sup>
                    </h2>
                    <div>
                        <h3 className="subtitle subtitle--margin">
                            KOHTAUSLÄÄKKEIDEN KESKIMÄÄRÄINEN KOKONAISKÄYTTÖ* (vrk/kk) potilailla, joilla oli krooninen migreeni ja lääkepäänsärky<sup>4</sup>
                        </h3>
                        <div className="chart-container">
                            <div className="graph">
                                <div className="graph-container inverted">
                                    <div className="graph-bar inverted teal graph-bar-1">
                                        20.6
                                        <div className="bottom-index">135</div>
                                    </div>
                                    <div className="graph-bar inverted steal graph-bar-2">
                                        20.7
                                        <div className="bottom-index">144</div>
                                    </div>
                                    <div className="graph-bar inverted gray graph-bar-3">
                                        19.8
                                        <div className="bottom-index">142</div>
                                    </div>
                                    <div className="bar-spacer"></div>
                                    <div className="graph-bar inverted teal graph-bar-4">
                                        10.8
                                        <div className="bottom-index">132</div>
                                    </div>
                                    <div className="graph-bar inverted steal graph-bar-5">
                                        12.2
                                        <div className="bottom-index">141</div>
                                    </div>
                                    <div className="graph-bar inverted gray graph-bar-6">
                                        14.8
                                        <div className="bottom-index">145</div>
                                    </div>
                                    <div className="bar-spacer"></div>
                                    <div className="graph-bar inverted teal graph-bar-7">
                                        10.6
                                        <div className="bottom-index">120</div>
                                    </div>
                                    <div className="graph-bar inverted steal graph-bar-8">
                                        10.5
                                        <div className="bottom-index">136</div>
                                    </div>
                                    <div className="graph-bar inverted gray graph-bar-9">
                                        14.0
                                        <div className="bottom-index">123</div>
                                    </div>
                                </div>
                                <img className="chart-image" src={graphFrame}
                                     alt="migraine frequency chart" />
                            </div>
                            <div className="chart-note chart-note--size">
                                <img className="chart-note__icon" src={noteIcon}
                                     alt="chart note icon" />
                                    <p className="chart-note__text">
Kohtauslääkkeiden käyttö väheni ja käyttö ei enää  ylittänyt ICHD-3 MOH -kriteerien kynnysarvoa<sup>4</sup>
                                    </p>
                            </div>
                        </div>
                    </div>
                    <p className="chart-info chart-info--margin">
                        Kuva muokattu lähteestä Marmura et al. <em>Headache</em>. 2021 Oct;61(9):1421-1431.
                    </p>
                    <ul className="list">
                        <li className="list__item">
                            Triptaanien sekä lääkkeiden kokonaiskäyttö väheni<sup>4</sup>
                        </li>
                        <li className="list__item">
                           Potilaille ei annettu lääkepäänsärkyä koskevaa neuvontaa tai ohjeistusta<sup>4</sup>
                        </li>
                    </ul>
                    <ul className="footnote-list">
                        <li className="footnote-list__item">
*Kohtauslääkepäivien määrä on niiden päivien summa, joina tarvittiin triptaania, ergotamiinia, opioidia, tavallista tai yhdistelmäkipulääkettä. Jos potilas käytti kahden eri ryhmän lääkettä samana päivänä, ne laskettiin kahdeksi päiväksi.
                        </li>
                        <li className="footnote-list__item">
<strong>ICHD-3</strong>, International classification of headache disorders, 3rd edition, kansainvälinen päänsärkysairauksien luokitus; <strong>MOH</strong>, medication overuse headache, lääkepäänsärky
                        </li>
                    </ul>
                    <img className="logo" src={logoVyepti}
                         alt="logo vyepti" />
            </div>
        </main>
    )
}

export default MohAcute;