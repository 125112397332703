import {
    CARD_SUMMARY_FLIP,
} from "../actions/types";

const initialState = {
    cards: [
            {id: 1, flipped: false},
            {id: 2, flipped: false},
            {id: 3, flipped: false},
            {id: 4, flipped: false},
            {id: 5, flipped: false},
            {id: 6, flipped: false},
        ]
};

function mainReducer(state = initialState, action) {
    // noinspection JSRedundantSwitchStatement
    switch (action.type) {
        case CARD_SUMMARY_FLIP:
            return Object.assign({}, state, {
                cards: [...state.cards].map(c => {
                    if(c.id === action.payload.cardId){
                        return {...c, flipped: !c.flipped}
                    }else{
                        return {...c}
                    }
                })
            });
        default:
            return state;
    }
}

export default mainReducer;