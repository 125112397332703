import {
    CARD_FLIP,
    EFFECT_CHANGE_REQUESTED,
    PAGE_CHANGE_REQUESTED,
    SLIDER_VALUE_CHANGED,
    CLOSE_MOH_MODAL,
    OPEN_MOH_MODAL,
    CARD_SUMMARY_FLIP,
    CHANGE_VIEW,
    CLOSE_SAFETY_MODAL,
    TOGGLE_SAFETY_MODAL,
    CLOSE_SAFETY_MODAL_INNER,
    OPEN_SAFETY_MODAL_INNER,
    CLOSE_REFERENCE_MODAL,
    OPEN_REFERENCE_MODAL,
    TOGGLE_REFERENCE_MODAL,
    TOGGLE_FULL_REFERENCE_MODAL,
    CLOSE_FULL_REFERENCE_MODAL,
    OPEN_FULL_REFERENCE_MODAL,
    TOGGLE_REVIEW_BUTTONS,
    TIME_SEND_REQUESTED,
    SET_SESSION_ID,
    REVIEW_SEND_REQUESTED,
    CLOSE_PRODUCT_INFORMATION_MODAL,
    TOGGLE_PRODUCT_INFORMATION_MODAL,
} from "./types";

export function changePageEffects(payload) {
    return {type: EFFECT_CHANGE_REQUESTED, payload};
}

export function moveToNextPage(payload) {
    return {type: PAGE_CHANGE_REQUESTED, payload};
}

export function changeSliderValue(payload) {
    return {type: SLIDER_VALUE_CHANGED, payload};
}

export function flipCard(payload) {
    return {type: CARD_FLIP, payload};
}

export function closeMohModal(payload) {
    return {type: CLOSE_MOH_MODAL, payload};
}

export function openMohModal(payload) {
    return {type: OPEN_MOH_MODAL, payload};
}

export function flipSummaryCard(payload) {
    return {type: CARD_SUMMARY_FLIP, payload};
}

export function changeView(payload) {
    return {type: CHANGE_VIEW, payload};
}

export function closeSafetyModal() {
    return {type: CLOSE_SAFETY_MODAL};
}

export function safetyModalToggle() {
    return {type: TOGGLE_SAFETY_MODAL};
}

export function closeSafetyModalInner() {
    return {type: CLOSE_SAFETY_MODAL_INNER};
}

export function openSafetyModalInner() {
    return {type: OPEN_SAFETY_MODAL_INNER};
}

export function closeReferenceModal() {
    return {type: CLOSE_REFERENCE_MODAL};
}

export function openReferenceModal() {
    return {type: OPEN_REFERENCE_MODAL};
}

export function openFullReferenceModal() {
    return {type: OPEN_FULL_REFERENCE_MODAL};
}

export function closeProductInformationModal() {
    return {type: CLOSE_PRODUCT_INFORMATION_MODAL};
}

export function productInformationModalToggle() {
    return {type: TOGGLE_PRODUCT_INFORMATION_MODAL};
}

export function referenceModalToggle() {
    return {type: TOGGLE_REFERENCE_MODAL};
}

export function fullReferenceModalToggle() {
    return {type: TOGGLE_FULL_REFERENCE_MODAL};
}

export function toggleReviewButtons() {
    return {type: TOGGLE_REVIEW_BUTTONS};
}

export function closeFullReferenceModal() {
    return {type: CLOSE_FULL_REFERENCE_MODAL};
}
export function setSessionId(payload) {
    return {type: SET_SESSION_ID, payload};
}

export function sendTimeStatistics(payload) {
    return {type: TIME_SEND_REQUESTED, payload};
}

export function sendReviewStatistics(payload) {
    return {type: REVIEW_SEND_REQUESTED, payload};
}
