import logoVyepti from '../images/vyepti_logo_fix.png';
import efficaciousIcon from '../images/efficacious-icon.png';
import lightFlipArrowIcon from '../images/light-flip-arrow.png';
import tealFlipArrowIcon from '../images/teal-flip-arrow.png';
import fastIcon from '../images/fast-icon.png';
import sustainedIcon from '../images/sustained-icon.png';
import React from "react";
import '../css/Summary.scss';
import {useDispatch, useSelector} from "react-redux";
import {flipSummaryCard} from "../actions";


function Summary() {
    const {cards} = useSelector(state => state.summary);
    const dispatch = useDispatch();
    const handleFlipCard = (cardId) => {
        let payload = {
            cardId
        }
        dispatch(flipSummaryCard(payload));
    };

    return (
        <main className="fade-in react-page-5 background background--big">
            <div className="wrapper">
                <h2 className="title title--margin">
                    VYEPTI® ON TEHOKAS MIGREENIN estohoito
                </h2>
                <div>
                    <div className="grid">
                        <div className="grid__column grid__column--margin">
                            <div className="card">
                                <div className={`card__body ${cards.filter(card => card.id === 1).map(filteredCard =>(filteredCard.flipped ? 'card--flipped': ''))}`}>
                                    <div className="card__face">
                                        <div className="card__icon-container">
                                            <img className="card__icon"
                                                 src={efficaciousIcon}
                                                 alt="efficacious icon" />
                                        </div>
                                        <p className="card__title text--teal">TEHOKAS</p>
                                        <button onClick={() => handleFlipCard(1)} className="flip__btn bg--teal">
                                            <img className="flip__icon"
                                                 src={lightFlipArrowIcon}
                                                 alt="light flip arrow" />
                                        </button>
                                    </div>
                                    <div className="card__back bg--teal">
                                        <p className="card__text">
                                            Vyepti® <strong>vähensi merkitsevästi</strong> migreenipäiviä; 58 % potilaista sai vasteen<sup>2</sup>*
                                        </p>
                                        <button onClick={() => handleFlipCard(1)} className="flip__btn bg--light">
                                            <img className="flip__icon" src={tealFlipArrowIcon}
                                                 alt="teal flip arrow" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className={`card__body ${cards.filter(card => card.id === 2).map(filteredCard =>(filteredCard.flipped ? 'card--flipped': ''))}`}>
                                    <div className="card__face">
                                        <p className="card__title text--dark-gray">
                                            LÄÄKEPÄÄNSÄRKY
                                        </p>
                                        <button onClick={() => handleFlipCard(2)} className="flip__btn bg--teal">
                                            <img className="flip__icon"
                                                 src={lightFlipArrowIcon}
                                                 alt="light flip arrow" />
                                        </button>
                                    </div>
                                    <div className="card__back bg--teal">
                                        <p className="card__text">
                                            Vyepti® mahdollistaa <strong>lääkepäänsäryn noidankehän katkaisun</strong><sup>4,9†</sup>
                                        </p>
                                        <button onClick={() => handleFlipCard(2)} className="flip__btn bg--light">
                                            <img className="flip__icon" src={tealFlipArrowIcon}
                                                 alt="teal flip arrow" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid__column grid__column--margin">
                            <div className="card">
                                <div className={`card__body ${cards.filter(card => card.id === 3).map(filteredCard =>(filteredCard.flipped ? 'card--flipped': ''))}`}>
                                    <div className="card__face">
                                        <div className="card__icon-container">
                                            <img className="card__icon"
                                                 src={fastIcon}
                                                 alt="fast icon" />
                                        </div>
                                        <p className="card__title text--steel">NOPEA</p>
                                        <button onClick={() => handleFlipCard(3)} className="flip__btn bg--teal">
                                            <img className="flip__icon"
                                                 src={lightFlipArrowIcon}
                                                 alt="light flip arrow" />
                                        </button>
                                    </div>
                                    <div className="card__back bg--teal">
                                        <p className="card__text">
                                            Vyepti® tehoaa jo <strong>1. päivänä infuusion</strong> jälkeen<sup>1, 2</sup>
                                        </p>
                                        <button onClick={() => handleFlipCard(3)} className="flip__btn bg--light">
                                            <img className="flip__icon" src={tealFlipArrowIcon} alt="teal flip arrow" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className={`card__body ${cards.filter(card => card.id === 4).map(filteredCard =>(filteredCard.flipped ? 'card--flipped': ''))}`}>
                                    <div className="card__face">
                                        <p className="card__title text--dark-gray">LASKIMOINFUUSIO</p>
                                        <button onClick={() => handleFlipCard(4)} className="flip__btn bg--teal">
                                            <img className="flip__icon"
                                                 src={lightFlipArrowIcon}
                                                 alt="light flip arrow" />
                                        </button>
                                    </div>
                                    <div className="card__back bg--teal">
                                        <p className="card__text">
                                            Vyepti® annostellaan <strong>30 minuutin laskimoinfuusioina 12 viikon välein</strong><sup>6</sup>
                                        </p>
                                        <button onClick={() => handleFlipCard(4)} className="flip__btn bg--light">
                                            <img className="flip__icon" src={tealFlipArrowIcon}
                                                 alt="teal flip arrow" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="grid__column grid__column--margin">
                            <div className="card">
                                <div className={`card__body ${cards.filter(card => card.id === 5).map(filteredCard =>(filteredCard.flipped ? 'card--flipped': ''))}`}>
                                    <div className="card__face">
                                        <div className="card__icon-container">
                                            <img className="card__icon"
                                                 src={sustainedIcon}
                                                 alt="sustained icon" />
                                        </div>
                                        <p className="card__title text--red">Pitkävaikutteinen</p>
                                        <button onClick={() => handleFlipCard(5)} className="flip__btn bg--teal">
                                            <img className="flip__icon"
                                                 src={lightFlipArrowIcon}
                                                 alt="light flip arrow" />
                                        </button>
                                    </div>
                                    <div className="card__back bg--teal">
                                        <p className="card__text">
                                            Yli 2/3 Vyepti®-potilaista raportoivat <strong>toimintakyvyn parantumisesta 2 vuoden seurannan aikana</strong><sup>3</sup>
                                        </p>
                                        <button onClick={() => handleFlipCard(5)} className="flip__btn bg--light">
                                            <img className="flip__icon" src={tealFlipArrowIcon}
                                                 alt="teal flip arrow" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className={`card__body ${cards.filter(card => card.id === 6).map(filteredCard =>(filteredCard.flipped ? 'card--flipped': ''))}`}>
                                    <div className="card__face">
                                        <p className="card__title text--dark-gray">SIEDETTÄVYYS</p>
                                        <button onClick={() => handleFlipCard(6)} className="flip__btn bg--teal">
                                            <img className="flip__icon"
                                                 src={lightFlipArrowIcon}
                                                 alt="light flip arrow" />
                                        </button>
                                    </div>
                                    <div className="card__back bg--teal">
                                        <p className="card__text">
                                            Vyepti® on hyvin siedetty.
                                            <br></br>
                                            Vyepti®-infuusion aikana tai jälkeen <strong>ei tarvita erityistä seurantaa</strong><sup>6</sup>**
                                        </p>
                                        <button onClick={() => handleFlipCard(6)} className="flip__btn bg--light">
                                            <img className="flip__icon" src={tealFlipArrowIcon}
                                                 alt="teal flip arrow" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ul className="footnote-list">
                        <li className="footnote-list__item">
                            †Noidankehän katkaisu tarkoittaa määritelmän mukaan, että potilas ei enää täytä akuuttilääkkeen käytön mukaisia lääkepäänsäryn
                            ICHD-3-kriteereitä ja päänsärkypäivien määrä jää kroonisen migreenin kynnysarvon alle (≥ 15 päivää/kuukausi 3 kuukauden ajan).<sup>9</sup>
                            &nbsp;*Vaste tarkoittaa kuukausittaisten migreenipäivien 50 % vähenemä.
                        </li>
                        <li className="footnote-list__item">
                            **Hoitavan terveydenhuollon ammattilaisen täytyy tarkkailla tai valvoa potilaita infuusion aikana ja sen jälkeen tavallisen kliinisen käytännön mukaisesti.
                        </li>
                        {/*<li className="footnote-list__item">*/}
                        {/*    <strong>IV</strong>, intravenous; <strong>MOH</strong>, medication*/}
                        {/*    overuse headache.*/}
                        {/*</li>*/}
                    </ul>
                    <img className="logo" src={logoVyepti}
                         alt="logo vyepti" />
                </div>
            </div>
        </main>
    )
}

export default Summary;