import {useDispatch, useSelector} from "react-redux";
import {closeSafetyModal, openSafetyModalInner} from "../../actions";
import backIcon from '../../images/back-icon.png';
import React from "react";


function SafetyModal() {
    const {safetyModalOpen} = useSelector(state => state.main);
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(closeSafetyModal());
    };
    const handleOpenInnerModal = () => {
        dispatch(openSafetyModalInner());
    }

    return (
        <div id="safety-modal-fs" className={`safety-modal background background--bottom ${safetyModalOpen ? 'safety-visible' : ''}`}>
            <div className="safety-main">
                <div className="wrapper">
                    <button onClick={handleClose} className="back-btn">
                        <img src={backIcon} alt="back icon" />
                    </button>
                    <h2 className="title title--margin">
                        VYEPTI®-INFUUSION AIKANA TAI SEN JÄLKEEN EI TARVITA ERITYISTÄ SEURANTAA<sup>6</sup>*
                    </h2>
                    <div>
                        <ul className="list list--size">
                            <li className="list__item">
                                Vyepti® on hyvin siedetty, alle 2 % potilaista keskeytti hoidon haittavaikutusten takia<sup>8</sup>
                            </li>
                            <li className="list__item">
                                Yleisimmät haittavaikutukset kliinisissä tutkimuksissa olivat nenänielun tulehdus ja yliherkkyysreaktiot ja useimmiten ne ovat olleet lieviä tai keskivaikeita<sup>6,8</sup>
                            </li>
                            <ul className="safety-list">
                                <li className="safety-list__item">
                                    Haittavaikutusten ilmaantuvuus on vähentynyt seuraavien annosten jälkeen<sup>8</sup>
                                </li>
                                <li className="safety-list__item">
                                    Yliherkkyys on ilmennyt infuusion aikana tai heti sen jälkeen. Reaktio on mennyt ohi ilman hoitoa yleensä 1 vuorokauden kuluessa<sup>8</sup>
                                </li>
                                <li className="safety-list__item">
                                    Infuusion yhteydessä ilmoitettuja muita oireita ovat mm. hengitystieoireet (nenän tukkoisuus, nuha, nielun ärsytys, yskä, aivastelu, hengenahdistus) ja uupumus. Useimmat näistä tapahtumista eivät olleet vakavia, ja ne olivat luonteeltaan ohimeneviä.<sup>6</sup>
                                </li>
                            </ul>
                        </ul>
                        <ul className="footnote-list">
                            <li className="footnote-list__item">
*Hoitavan terveydenhuollon ammattilaisen täytyy tarkkailla tai valvoa potilaita infuusion aikana ja sen jälkeen tavallisen kliinisen käytännön mukaisesti.
                            </li>
                        <li className="footnote-list__item">
                            Kirjallisuusviitteet:<br></br>
                            {/*1. Ashina et al. <em>Cephalalgia</em> 2020 Mar;40(3):241-254.<br></br>*/}
                            {/*2. Lipton et al. <em>Neurology</em> 2020;94;e1365-e1377.<br></br>*/}
                            6. Vyepti® valmisteyhteenveto.<br></br>
                            8. Smith et al. <em>The Journal of Headache and Pain</em> (2021) 22:16.
                        </li>
                    </ul>

                        <div className="iv-summary">
                            <div className="iv-summary__content">
                                <button onClick={handleOpenInnerModal} className="safety-btn">
                                    <img className="safety-btn__icon" alt="pop-up icon" />
                                </button>
                                <p className="iv-summary__description text--teal">
                                    Tiivistelmä kliinisissä tutkimuksissa hoidon<br></br> yhteydessä ilmenneistä haittatapahtumista<sup>8</sup>
                                </p>
                            </div>
                            <img className="iv-summary__background" alt="iv" />
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default SafetyModal;