import logoVyepti from '../images/vyepti_logo_fix.png';
import fastIcon from '../images/fast-background-icon.png';
import noteIcon from '../images/page11/note-icon.png';
import graphFrame from '../images/page11/graph-frame_slide22_korj.png';
import React from "react";
import '../css/FastHoursData.scss';


function FastHoursData() {
    return (
        <main className="fade-in react-page-11 background">
            <div className="wrapper">
                <img className="background-icon"
                     src={fastIcon} alt="background icon" />
                    <h2 className="title title--margin">
                        MIGREENIKOHTAUKSEN AIKANA ALOITETTU VYEPTI®-HOITO TEHOAA JO 2 TUNNISSA<sup>11</sup>
                    </h2>
                    <div>
                        <h3 className="subtitle subtitle--margin">
                            POTILAIDEN OIREET PARANIVAT, KUN VYEPTI® ALOITETTIIN MIGREENIKOHTAUKSEN AIKANA
                        </h3>
                        <div className="chart-container">
                            <div className="graph">
                                <div className="graph-container inverted">
                                    <div className="graph-bar inverted teal graph-bar-1">
                                        23.5%
                                        <div>***</div>
                                    </div>
                                    <div className="graph-bar inverted gray graph-bar-2">
                                        12.0%
                                    </div>
                                    <div className="bar-spacer"></div>
                                    <div className="graph-bar inverted teal graph-bar-3">
                                        55.5%
                                        <div>***</div>
                                    </div>
                                    <div className="graph-bar inverted gray graph-bar-4">
                                        35.8%
                                    </div>
                                    <div className="bar-spacer"></div>
                                    <div className="graph-bar inverted teal graph-bar-5">
                                        31.5%
                                        <div>***</div>
                                    </div>
                                    <div className="graph-bar inverted gray graph-bar-6">
                                        59.9%
                                    </div>
                                </div>
                                <img className="chart-image" src={graphFrame}
                                     alt="migraine frequency chart" />
                            </div>
                            <div className="chart-note chart-note--size">
                                <img className="chart-note__icon" src={noteIcon}
                                     alt="chart note icon" />
                                    <p className="chart-note__text">
                                        Tehokkaasti migreeniä estävä hoito voi olla tarpeen lääkepäänsäryn ja migreenin kroonistumisen ehkäisemiseksi<sup>11</sup>
                                    </p>
                            </div>
                        </div>
                        <p className="chart-info chart-info--margin">
                            Kuva muokattu lähteestä Winner et al <em>JAMA</em>;325(23):2348-2356.
                        </p>
                    </div>
                    <ul className="list list--size">
                        <li className="list__item">
                            Koska migreenikohtauksen aikana annosteltu Vyepti® lievitti nopeasti päänsärkyä ja haittaavimpia oireita, potilaat pystyivät vähentämään kohtauslääkkeiden käyttöä<sup>11</sup>
                        </li>
                    </ul>
                    <ul className="footnote-list">
                        <li className="footnote-list__item">
                            Haittaavimmat oireet ovat potilaan valitsemia oireita, kuten valonarkuus, pahoinvointi ja ääniherkkyys.<sup>11</sup> *** p &lt; 0,001 lumeeseen verrattuna
                        </li>
                        {/*<li className="footnote-list__item">*/}
                        {/*    <strong>MBS</strong>, most bothersome symptoms, haittaavimmat oireet*/}
                        {/*</li>*/}
                    </ul>
                    <img className="logo" src={logoVyepti}
                         alt="logo vyepti" />
            </div>
        </main>
    )
}

export default FastHoursData;