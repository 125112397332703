import {all, call, put, takeEvery, takeLatest} from 'redux-saga/effects'
import {
    REVIEW_SEND_REQUESTED,
    REVIEW_SEND_SUCCEEDED,
    REVIEW_SEND_FAILED,
    TIME_SEND_REQUESTED,
    TIME_SEND_SUCCEEDED,
    TIME_SEND_FAILED,

} from "../actions/types";

import {api} from "../api";

function* sendReview(action) {
    try {
        const data = yield call(() => api.post('/api/review/', action.payload));
        yield put({type: REVIEW_SEND_SUCCEEDED, payload: data});
    } catch (err) {
        yield put({type: REVIEW_SEND_FAILED, payload: err});
    }
}

function* initSendReview() {
    yield takeLatest(REVIEW_SEND_REQUESTED, sendReview);
}


function* sendTimeSpent(action) {
    try {
        const data = yield call(() => api.post('/api/time/', action.payload));
        yield put({type: TIME_SEND_SUCCEEDED, payload: data});
    } catch (err) {
        yield put({type: TIME_SEND_FAILED, payload: err});
    }
}

function* initSendTimeSpent() {
    yield takeLatest(TIME_SEND_REQUESTED, sendTimeSpent);
}


export function* initStatistics() {
    yield all([
        initSendReview(),
        initSendTimeSpent()
    ]);
}