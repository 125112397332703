import homePageImage from '../images/homepage-img.png';
import logoVyepti from '../images/vyepti_logo_fix.png';
import React from "react";
import {useDispatch} from "react-redux";
import {moveToNextPage} from "../actions";
import '../css/HomePage.scss';
import {BASICS} from "../settings";


function HomePage() {
    const dispatch = useDispatch();

    const handleMove = () => {
        let payload = {
            page: BASICS
        }
        dispatch(moveToNextPage(payload));
    };
    return (
        <main className="overflow react-page-0">
            <div className="wrapper" onClick={handleMove}>
                <div>
                    <h1 className="heading text--light">VYEPTI®</h1>
                    <p className="subheading text--light">Tehokas laskimoon annosteltava monoklonaalinen CGRP-vasta-aine<sup>6</sup></p>
                </div>
                <div className="homepage-img"><img src={homePageImage} alt='homepage'/></div>
                <p className="heading-description text--light">Vyepti® on tarkoitettu migreenin estohoitoon aikuisilla, joilla on vähintään 4 migreenipäivää kuukaudessa<sup>6</sup></p>
                <div className='footnote-container'>
                    <ul className="footnote-list text--light">
                        {/*<li className="footnote-list__item">*Tehokas on määritelmän mukaan sellainen migreenin estohoito, joka alkaa tehota jo 1. päivänä ja jonka vaikutus kestää 3 kuukautta CGRP, kalsitoniinigeeniin liittyvä peptidi*/}
                        {/*</li>*/}
                        <li className="footnote-list__item"><strong>FI-VYEP-0024 </strong>(08/2022)</li>
                        <li className="footnote-list__item">6. Vyepti® valmisteyhteenveto.</li>

                        <li className="footnote-list__item">
                            <p className='black-text'><span className="fimea-triangle">Tähän lääkevalmisteeseen kohdistuu lisäseuranta.</span></p>
                        </li>
                        <img className="logo" src={logoVyepti} alt="logo vyepti"/>
                        </ul>
                </div>
            </div>
        </main>
    )
}

export default HomePage;