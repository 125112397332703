import logoVyepti from '../images/vyepti_logo_fix.png';
import sustainedIcon from '../images/sustained-background-icon.png';
import graphFrame from '../images/page13/graph-frame_slide25.png';
import darkBlueLine from '../images/page13/dark-blue-line.png';
import blueLine from '../images/page13/blue-line.png';
import React from "react";
import '../css/SustainedYearData.scss';

function SustainedYearData() {
    return (
        <main className="fade-in background react-page-13">
            <div className="wrapper">
                <img className="background-icon"
                     src={sustainedIcon}
                     alt="background icon" />
                    <h2 className="title title--margin">
                        VYEPTI® estää TEHOKKAASTI JA PITKÄVAIKUTTEISESTI MIGREENIÄ<sup>5</sup>*
                    </h2>
                    <div>
                        <h3 className="subtitle subtitle--margin">
                            Migreenipäivien vähenemä, krooniset migreenipotilaat
                        </h3>
                        <div className="chart-container">
                            <div className="chart-frame">
                                <div className="chart-frame-container">
                                    <div className="line line-1">
                                        <img src={darkBlueLine} alt='blue line dark'/>
                                    </div>
                                    <div className="line line-2">
                                        <img src={blueLine} alt='blue line' />
                                    </div>
                                </div>
                                <div className="chart-image">
                                    <img src={graphFrame} alt="dosing interval chart" />
                                </div>

                            </div>
                        </div>
                        <p className="chart-info chart-info--size">
                            Kuva muokattu lähteestä Silberstein et al. <em>The Journal of Headache and Pain</em> (2020) 21:120.
                        </p>
                    </div>
                    <ul className="list list--size">
                        <li className="list__item">
                            Vyepti® esti migreeniä 6 kuukauden seuranta-aikana tehokkaasti ja pitkävaikutteisesti; migreenipäiviä oli lähtötilanteeseen verrattuna keskimäärin 8 päivää vähemmän kuukaudessa<sup>5</sup>
                        </li>
                        <li className="list__item">
                            Migreenipäivien pysyvän vähenemisen ansiosta potilaat pystyivät osallistumaan päivittäisiin toimiin esimerkiksi työssä, koulussa ja sosiaalisissa riennoissa<sup>5</sup>†
                        </li>
                    </ul>
                    <ul className="footnote-list">
                        <li className="footnote-list__item">
* PROMISE-1 tutki episodista migreeniä sairastavia, joilla MMD-keskiarvo oli kaikissa hoitoryhmissä ~8,6.
                            Vyepti® 100 mg ja 300 mg vähensivät MMD-keskiarvoja lumeeseen verrattuna,
                            ja vaikutus säilyi 1 vuoden hoidon ajan (100 mg, viikoilla 1–12 -3,9,
                            viikoilla 13–24 -4,5, viikoilla 25–36 -4,7 ja viikoilla 37–48 -4,5
                            päivää; vastaavat arvot 300 mg:n annokselle -4,3, -4,8, -5,1 ja -5,3 päivää ja
                            lumeelle -3,2, -3,8, -4,0 ja -4,0 päivää).<sup>17</sup> †Kuten HIT-6-kokonaispistemäärien parannus osoittaa.
                            Niiden potilaiden %-osuus, joiden toimintakykyä migreeni heikensi (HIT-6-kokonaispistemäärä ≥ 60),
                            laski ~88 %:n lähtötasolta 51,4 %:iin viikolla 12 ja 43,5 %:iin viikolla 24, kun hoitona oli Vyepti® 100 mg.
                            Vastaavat luvut olivat 42,9 % ja 39,7 %, kun hoitona oli Vyepti® 300 mg ja 60,1 % ja 55,3 %, kun hoitona oli lume.<sup>5</sup>
                        </li>
                        <li className="footnote-list__item">
<strong>HIT-6</strong>, 6-item headache impact test, 6-kohtainen päänsäryn vaikutusta mittaava testi; <strong>MMD</strong>, monthly migraine day, migreenipäiviä kuukaudessa
                        </li>
                    </ul>
                    <img className="logo" src={logoVyepti} alt="logo vyepti" />
            </div>
        </main>
    )
}

export default SustainedYearData;