import {combineReducers} from "redux";
import main from "./main";
import impactedPatients from "./impactedPatients";
import summary from "./summary";
import fastResponderRates from "./fastResponderRates";

export default combineReducers({
    main,
    impactedPatients,
    summary,
    fastResponderRates
});