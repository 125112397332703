import {SLIDER_VALUE_CHANGED, CARD_FLIP} from "../actions/types";

const initialState = {
    sliderValue: 0,
    cardFlipped: false,
};

function impactedPatientsReducer(state = initialState, action) {
    // noinspection JSRedundantSwitchStatement
    switch (action.type) {
        case SLIDER_VALUE_CHANGED:
            return Object.assign({}, state, {
                sliderValue: action.payload.sliderValue
            });
        case CARD_FLIP:
            return Object.assign({}, state, {
                cardFlipped: !state.cardFlipped
            });
        default:
            return state;
    }
}

export default impactedPatientsReducer;