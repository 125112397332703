import logoVyepti from '../images/vyepti_logo_fix.png';
import React from "react";
import '../css/VyeptiInMoh.scss';
import ivImg from "../images/page4/iv-img.png";
// import {useDispatch} from "react-redux";
// import {openMohModal} from "../actions";


function Tolerability() {
    // const dispatch = useDispatch();
    //
    // const handleOpenMohModal = () => {
    //     dispatch(openMohModal());
    // };

    return (
        <main className="fade-in react-page-4 background background--big">
            <div className="wrapper">
                <h2 className="title title--margin">
                    Siedettävyys
                </h2>
                <div>
                    <div className="ingress-container">
                        <p className="ingress">
                        Vyepti®n yleisimmät haittavaikutukset kliinisissä tutkimuksissa olivat nenänielun tulehdus ja yliherkkyys.<sup>6</sup> Useimmat yliherkkyysreaktiot tapahtuivat infuusion aikana eivätkä olleet vakavia. Ne menivät ohi tavanomaisella lääketieteellisellä hoidolla tai seurannalla ilman hoitoa, yleensä vuorokauden kuluessa.<sup>8</sup>
                        </p>
                        <p className="small-text">
                            Vyepti® on hyvin siedetty. Alle 2 % potilaista keskeytti hoidon haittavaikutusten takia.<sup>8</sup>
                        </p>
                        <p className="list__title">
                            YLEISET HAITTAVAIKUTUKSET KLIINISISSÄ TUTKIMUKSISSA<sup>6,8 *</sup>
                        </p>
                    </div>
                    <div className="safety-table-container">
                        <table className="safety-table-2">
                            <thead className="text--teal">
                            <tr>
                                <th className="border-none"></th>
                                <th>Vyepti®</th>
                                <th></th>
                                <th>Lume</th>
                            </tr>
                            <tr>
                                <th rowSpan="2"><em></em></th>
                                <th>300 mg</th>
                                <th>100 mg</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Nenänielun tulehdus</td>
                                <td>8 %</td>
                                <td>6 %</td>
                                <td>6 %</td>
                            </tr>
                            <tr>
                                <td>Yliherkkyysreaktio</td>
                                <td>4 %</td>
                                <td>3 %</td>
                                <td>1 %</td>
                            </tr>
                            <tr>
                                <td>Uupumus</td>
                                <td>3 %</td>
                                <td>3 %</td>
                                <td>2 %</td>
                            </tr>
                            </tbody>
                        </table>
                        <p style={{marginTop: "2rem"}}>* Tarkemmat tiedot haittavaikutuksista löydät Vyepti®n valmisteyhteenvedosta</p>
                    </div>
                    <div className="iv">
                        <img className="iv__img-2" src={ivImg} alt="IV" />
                    </div>
                    {/*<ul className="footnote-list">*/}
                    {/*    <li className="footnote-list__item">*/}
                    {/*        *Joillekin voi olla hyötyä 300 mg:n annoksesta<sup>1</sup>*/}
                    {/*    </li>*/}
                    {/*    <li className="footnote-list__item">*/}
                    {/*        <strong>CGRP</strong>, calcitonin gene-related peptide, kalsitoniinigeeniin liittyvä peptidi*/}
                    {/*    </li>*/}
                    {/*</ul>*/}
                    <img className="logo" src={logoVyepti}
                         alt="logo vyepti" />
                </div>
            </div>
        </main>
    )
}

export default Tolerability;