export const EFFECT_CHANGE_REQUESTED = "EFFECT_CHANGE_REQUESTED";
export const PAGE_CHANGE_REQUESTED = "PAGE_CHANGE_REQUESTED";
export const SLIDER_VALUE_CHANGED = "SLIDER_VALUE_CHANGED";
export const CARD_FLIP = "CARD_FLIP";
export const CLOSE_MOH_MODAL = "CLOSE_MOH_MODAL";
export const OPEN_MOH_MODAL = "OPEN_MOH_MODAL";
export const CARD_SUMMARY_FLIP = "CARD_SUMMARY_FLIP";
export const CHANGE_VIEW = "CHANGE_VIEW";
export const CLOSE_SAFETY_MODAL = "CLOSE_SAFETY_MODAL";
export const TOGGLE_SAFETY_MODAL = "TOGGLE_SAFETY_MODAL";
export const CLOSE_SAFETY_MODAL_INNER = "CLOSE_SAFETY_MODAL_INNER";
export const OPEN_SAFETY_MODAL_INNER = "OPEN_SAFETY_MODAL_INNER";
export const CLOSE_REFERENCE_MODAL = "CLOSE_REFERENCE_MODAL";
export const OPEN_REFERENCE_MODAL = "OPEN_REFERENCE_MODAL";
export const TOGGLE_PRODUCT_INFORMATION_MODAL = "TOGGLE_PRODUCT_INFORMATION_MODAL";
export const TOGGLE_REFERENCE_MODAL = "TOGGLE_REFERENCE_MODAL";
export const CLOSE_PRODUCT_INFORMATION_MODAL = "CLOSE_PRODUCT_INFORMATION_MODAL";
export const TOGGLE_FULL_REFERENCE_MODAL = "TOGGLE_FULL_REFERENCE_MODAL";
export const CLOSE_FULL_REFERENCE_MODAL = "CLOSE_FULL_REFERENCE_MODAL";
export const OPEN_FULL_REFERENCE_MODAL = "OPEN_FULL_REFERENCE_MODAL";
export const TOGGLE_REVIEW_BUTTONS = "TOGGLE_REVIEW_BUTTONS";
export const SET_SESSION_ID = "SET_SESSION_ID";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";


//STATISTICS
export const REVIEW_SEND_REQUESTED = "REVIEW_SEND_REQUESTED";
export const REVIEW_SEND_SUCCEEDED = "REVIEW_SEND_SUCCEEDED";
export const REVIEW_SEND_FAILED = "REVIEW_SEND_FAILED";
export const TIME_SEND_REQUESTED = "TIME_SEND_REQUESTED";
export const TIME_SEND_SUCCEEDED = "REVIEW_SEND_SUCCEEDED";
export const TIME_SEND_FAILED = "TIME_SEND_FAILED";
