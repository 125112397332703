import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    safetyModalToggle,
    referenceModalToggle,
    moveToNextPage,
    fullReferenceModalToggle,
    productInformationModalToggle
} from "../../actions";
import sitemap from '../../images/sitemap.png'
import {EXTRA, SITEMAP} from "../../settings";

function SideBar() {
    const dispatch = useDispatch();
    const {safetyModalOpen, referenceModalOpen, page, productInformationModalOpen} = useSelector(state => state.main);

    const handleSafetyModalToggle = () => {
        dispatch(safetyModalToggle());
    };

    const handleProductInformationModalToggle = () => {
        dispatch(productInformationModalToggle());
    };

    const handleReferenceModalToggle = () => {
        if(page === SITEMAP || page === EXTRA){
            dispatch(fullReferenceModalToggle());
        }else{
            dispatch(referenceModalToggle());
        }
    };

    const handlePageChange = (_page) => {
        let payload = {
            page: _page
        }
        dispatch(moveToNextPage(payload));
    };

    return (
        <div className="fab fab-class">
            <div onClick={handleSafetyModalToggle} id="safety-btn" className={`item-modal ${safetyModalOpen ? 'item-red' : ''}`}>S</div>
            <div onClick={handleReferenceModalToggle} id="ref-btn-safety" className={`item-modal ${referenceModalOpen ? 'item-red' : ''}`}>Ref</div>
            <div onClick={handleProductInformationModalToggle} id="pi-btn" className={`item-modal ${productInformationModalOpen ? 'item-red' : ''}`}>PI</div>
            <div onClick={() => handlePageChange(SITEMAP)} className={`item item-modal sitemap-icon ${page===SITEMAP ? 'item-red' : ''}`}>
                <img className='sitemap-icon-img' src={sitemap} alt='Sitemap' />
            </div>
        </div>
    )
}

export default SideBar;