import {useDispatch, useSelector} from "react-redux";
import {closeSafetyModalInner} from "../../actions";


function SafetyModalInner() {
    const {safetyModalInnerOpen} = useSelector(state => state.main);
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(closeSafetyModalInner());
    };

    return (
        <div id="safety-modal" className={`modal ${safetyModalInnerOpen ? 'visible' : ''}`}>
            <div className="modal__card modal__card--safety">
                <button onClick={handleClose} className="modal__close-btn">
                    <img className="modal__close-img" alt="teal close icon" />
                </button>
                <h3 className="subtitle subtitle--margin">
                    TIIVISTELMÄ KLIINISISSÄ TUTKIMUKSISSA HOIDON YHTEYDESSÄ ILMENNEISTÄ HAITTATAPAHTUMISTA*
                </h3>
                <table className="safety-table">
                    <thead className="text--teal">
                    <tr>
                        <th className="border-none"></th>
                        <th>Vyepti®</th>
                        <th></th>
                        <th>Lume</th>
                    </tr>
                    <tr>
                        <th rowSpan="2"><em>Patients, n (%)</em></th>
                        <th>300 mg<br></br><em>(n = 823)</em></th>
                        <th>100 mg<br></br><em>(n = 701)</em></th>
                        <th><br></br><em>(N = 791)</em></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Ylähengitystieinfektio</td>
                        <td>64 (7.8)</td>
                        <td>45 (6.4)</td>
                        <td>48 (6.1)</td>
                    </tr>
                    <tr>
                        <td>Nenänielun tulehdus</td>
                        <td>72 (8.7)</td>
                        <td>44 (6.3)</td>
                        <td>41 (5.2)</td>
                    </tr>
                    <tr>
                        <td>Huimaus</td>
                        <td>16 (1.9)</td>
                        <td>27 (3.9)</td>
                        <td>21 (2.7)</td>
                    </tr>
                    <tr>
                        <td>Uupumus</td>
                        <td>24 (2.9)</td>
                        <td>20 (2.9)</td>
                        <td>13 (1.6)</td>
                    </tr>
                    </tbody>
                </table>

                <div className="chart-info-container">
                    <p className="chart-info">
                        *Ilmaantuvuus kaikissa Vyepti®-ryhmissä ≥ 2 % ja 2 prosenttiyksikköä suurempi kuin lumelääkettä saaneilla
                    </p>
                    <p className="chart-info">
                        Kuva muokattu lähteestä Smith et al. <em>The Journal of Headache and Pain</em> (2021) 22:16.
                    </p></div>
                <p></p>

            </div>
        </div>
    )
}

export default SafetyModalInner;