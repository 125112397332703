import logoVyepti from '../images/vyepti_logo_fix.png';
import graphFrame from '../images/page14/graph-frame_slide27_fix.png';
import baseLineArrow from '../images/page14/baseline-arrow_final.png';
import React from "react";
import '../css/MohReductions.scss';

/*TODO GRAAFIT SUOMEKSI*/
function MohReductions() {
    return (
        <main className="fade-in react-page-14 background">
            <div className="wrapper">
                    <h2 className="title title--margin">
                        Vyepti® vähentää migreenipäiviä potilailla, joilla on krooninen migreeni + lääkepäänsärky<sup>9</sup>
                    </h2>
                    <div className="chart-content">
                        <h3 className="subtitle subtitle--margin">Kuukausittaisten migreenipäivien muutos lähtötilanteesta<sup>9</sup>*</h3>
                        <div className="chart-container">
                            <div className="graph">
                                <div className="graph-container">
                                    <div className="graph-bar teal graph-bar-1">
                                        -8.4
                                        <div>***</div>
                                    </div>
                                    <div className="graph-bar steal graph-bar-2">
                                        -8.6
                                        <div>***</div>
                                    </div>
                                    <div className="graph-bar gray graph-bar-3">
                                        -5.4
                                    </div>
                                </div>
                                <img className="chart-image" src={graphFrame}
                                     alt="migraine frequency chart" />
                            </div>
                            <div className="baseline-arrow">
                                <img src={baseLineArrow} alt="baseline arrow" />
                            </div>
                        </div>
                        <p className="chart-info chart-info--size">
                            Kuva muokattu lähteestä Diener et al. <em>Headache.</em> 2021 Jan;61(1):125-136.
                        </p>
                    </div>
                    <ul className="list list--size">
                        <li className="list__item">
                            Vyepti®-hoidon teho alkoi jo 1. päivänä infuusion jälkeen ja pysyi 6 kuukautta; hoidon hyödyt olivat CM- ja MOH-potilailla nopeita ja pitkäaikaisia<sup>9</sup>
                        </li>
                    </ul>
                    <ul className="footnote-list">
                        <li className="footnote-list__item">
                            *Potilailla, joilla oli sekä CM että MOH, oli 28 vuorokauden alkujaksolla keskimäärin 16,7 migreenipäivää. Viikoilla 1–12 MMD-arvot laskivat Vyepti®-hoitoa saaneilla enemmän lähtötilanteesta kuin lumelääkettä saaneilla  (100 mg -8,4 MMD, p &lt; 0,0001 lumeeseen verrattuna; 300 mg -8,6 MMD, p &lt; 0,0001 lumeeseen verrattuna; lume -5,4 MMD)<sup>9</sup>
                        </li>
                        <li className="footnote-list__item">
                            <strong>MMD</strong>, montly migraine day, migreenipäiviä kuukaudessa; <strong>CM</strong>, chronic migraine, krooninen migreeni; <strong>MOH</strong>, medication overuse headache, lääkepäänsärky
                        </li>

                        <img className="logo" src={logoVyepti}
                             alt="logo vyepti" />
                    </ul></div>
        </main>
    )
}

export default MohReductions;