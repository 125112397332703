import logoVyepti from '../images/vyepti_logo_fix.png';
import homePage from '../images/sitemap/HOMEPAGE.jpg';
import effectiveVyepti from '../images/sitemap/EFFECTIVE_VYEPTI.jpg';
import effectiveOne from '../images/sitemap/EFFECTIVE_1.jpg';
import effectiveTwo from '../images/sitemap/EFFECTIVE_2.jpg';
import effectiveThree from '../images/sitemap/EFFECTIVE_3.jpg';
import effectiveFour from '../images/sitemap/EFFECTIVE_4.jpg';
import fastOne from '../images/sitemap/FAST_1.jpg';
import fastTwo from '../images/sitemap/FAST_2.jpg';
import longLastingOne from '../images/sitemap/LONG_LASTING_1.jpg';
import longLastingTwo from '../images/sitemap/LONG_LASTING_2.jpg';
import patientVyepti from '../images/sitemap/PATIENT.jpg';
import mohVyepti from '../images/sitemap/VYEPTI_MOH.jpg';
import mohVyeptiOne from '../images/sitemap/MEDICAL_HEADACHE_1.jpg';
import mohVyeptiTwo from '../images/sitemap/MEDICAL_HEADACHE_2.jpg';
import mohVyeptiThree from '../images/sitemap/MEDICAL_HEADACHE_3.jpg';
import IvOne from '../images/sitemap/IV_1.jpg';
import IvVyepti from '../images/sitemap/IV.jpg';
import summaryVyepti from '../images/sitemap/SUMMARY.jpg';
import tolerabilityVyepti from '../images/sitemap/TOLERABILITY.jpg';
import React from "react";
import '../css/Sitemap.scss';
import {useDispatch} from "react-redux";
import {moveToNextPage} from "../actions";
import {
    EFFECTIVE_1,
    EFFECTIVE_2,
    EFFECTIVE_3, EFFECTIVE_4,
    EFFECTIVE_VYEPTI, FAST_1, FAST_2,
    HOMEPAGE,
    IV, LONG_LASTING_1, LONG_LASTING_2, MEDICAL_HEADACHE_1, MEDICAL_HEADACHE_2, MEDICAL_HEADACHE_3,
    PATIENT,
    SUMMARY,
    VYEPTI_MOH,
    IV_1, TOLERABILITY,
} from "../settings";


function Sitemap() {
    const dispatch = useDispatch();

    const handlePageChange = (_page) => {
        let payload = {
            page: _page
        }
        dispatch(moveToNextPage(payload));
    };

    return (
        <main className="fade-in react-page-17">
            <div className="wrapper">
                <h2 className="title title--margin">
                    Sivukartta
                </h2>
                <div className='sitemap-main-wrapper'>
                    <div className='sitemap-item'>
                        <div onClick={() => handlePageChange(HOMEPAGE)}>
                            <h3>Aloitusnäyttö</h3>
                            <img src={homePage} alt='Homepage' />
                        </div>
                    </div>
                    <div className='sitemap-item'>
                        <div onClick={() => handlePageChange(EFFECTIVE_VYEPTI)}>
                            <h3>Tehokas Vyepti®</h3>
                            <img src={effectiveVyepti} alt='EFFECTIVE_VYEPTI' />
                        </div>
                        <div className='dashed-line-1'>
                        </div>
                        <div className='dashed-line-2'>
                        </div>
                        <div className='dashed-line-3'>
                        </div>
                        <div className='dashed-line-4'>
                        </div>
                        <div className='dashed-line-5'>
                        </div>
                        <div className='sitemap-item-child-wrapper-1'>
                             <div className='sitemap-item-child'>
                                <h3>TEHOKAS </h3>
                                 <div onClick={() => handlePageChange(EFFECTIVE_1)}>
                                     <img src={effectiveOne} alt='EFFECTIVE_1' />
                                 </div>
                            </div><div className='sitemap-item-child'>
                                 <div onClick={() => handlePageChange(EFFECTIVE_2)}>
                                    <img src={effectiveTwo} alt='EFFECTIVE_2' />
                                 </div>
                            </div>
                            <div className='sitemap-item-child'>
                                 <div onClick={() => handlePageChange(EFFECTIVE_3)}>
                                    <img src={effectiveThree} alt='EFFECTIVE_3' />
                                 </div>
                            </div>
                            <div className='sitemap-item-child'>
                                 <div onClick={() => handlePageChange(EFFECTIVE_4)}>
                                    <img src={effectiveFour} alt='EFFECTIVE_4' />
                                 </div>
                            </div>
                        </div>
                        <div className='sitemap-item-child-wrapper-2'>
                             <div className='sitemap-item-child'>
                                <h3>NOPEA</h3>
                                 <div onClick={() => handlePageChange(FAST_1)}>
                                    <img src={fastOne} alt='FAST_1' />
                                 </div>
                             </div>
                            <div className='sitemap-item-child'>
                                 <div onClick={() => handlePageChange(FAST_2)}>
                                    <img src={fastTwo} alt='FAST_2' />
                                 </div>
                            </div>
                        </div>
                        <div className='sitemap-item-child-wrapper-3'>
                             <div className='sitemap-item-child'>
                                <h3>PITKÄVAIKUTTEINEN</h3>
                                 <div onClick={() => handlePageChange(LONG_LASTING_1)}>
                                    <img src={longLastingOne} alt='LONG_LASTING_1' />
                                 </div>
                             </div>
                            <div className='sitemap-item-child'>
                                 <div onClick={() => handlePageChange(LONG_LASTING_2)}>
                                    <img src={longLastingTwo} alt='LONG_LASTING_2' />
                                 </div>
                            </div>
                        </div>
                    </div>
                    <div className='sitemap-item'>
                        <div onClick={() => handlePageChange(PATIENT)}>
                            <h3>Potilas </h3>
                            <img src={patientVyepti} alt='PATIENT' />
                        </div>
                    </div>
                    <div className='sitemap-item'>
                        <div onClick={() => handlePageChange(VYEPTI_MOH)}>
                        <h3>Vyepti® ja MOH</h3>
                        <img src={mohVyepti} alt='VYEPTI_MOH' />
                        </div>
                        <div className='dashed-line-6'>
                        </div>
                        <div className='dashed-line-7'>
                        </div>
                        <div className='dashed-line-8'>
                        </div>
                        <div className='sitemap-item-child-wrapper-4'>
                             <div className='sitemap-item-child'>
                                <h3>LÄÄKEPÄÄNSÄRKY</h3>
                                 <div onClick={() => handlePageChange(MEDICAL_HEADACHE_1)}>
                                    <img src={mohVyeptiOne} alt='MEDICAL_HEADACHE_1' />
                                 </div>
                            </div>
                            <div className='sitemap-item-child'>
                                 <div onClick={() => handlePageChange(MEDICAL_HEADACHE_2)}>
                                    <img src={mohVyeptiTwo} alt='MEDICAL_HEADACHE_2' />
                                 </div>
                            </div>
                            <div className='sitemap-item-child'>
                                <div onClick={() => handlePageChange(MEDICAL_HEADACHE_3)}>
                                    <img src={mohVyeptiThree} alt='MEDICAL_HEADACHE_3' />
                                 </div>
                            </div>
                        </div>

                    </div>
                    <div className='sitemap-item'>
                        <div onClick={() => handlePageChange(IV)}>
                            <h3>Laskimoinfuusio</h3>
                            <img src={IvVyepti} alt='IV' />
                        </div>
                        <div className='dashed-line-9'>
                        </div>
                        <div className='dashed-line-10'>
                        </div>
                        <div className='dashed-line-11'>
                        </div>
                        <div className='sitemap-item-child-wrapper-5'>
                             <div className='sitemap-item-child'>
                                <h3>FARMAKOLOGISET OMINAISUUDET</h3>
                                 <div onClick={() => handlePageChange(IV_1)}>
                                    <img src={IvOne} alt='IV_1' />
                                 </div>
                             </div>
                        </div>
                    </div>
                    <div className='sitemap-item'>
                        <div onClick={() => handlePageChange(TOLERABILITY)}>
                            <h3>Siedettävyys</h3>
                            <img src={tolerabilityVyepti} alt='TOLERABILITY' />
                        </div>
                    </div>
                    <div className='sitemap-item'>
                        <div onClick={() => handlePageChange(SUMMARY)}>
                            <h3>Tiivistelmä</h3>
                        <img src={summaryVyepti} alt='SUMMARY' />
                        </div>
                    </div>
                </div>
                <img className="logo" src={logoVyepti} alt="logo vyepti" />

            </div>
        </main>
    )
}

export default Sitemap;