import React from "react";
import {useSelector} from "react-redux";
import HomePage from "./HomePage";
import ThePowerOfVyepti from "./ThePowerOfVyepti";
import ImpactedPatient from "./ImpactedPatient";
import VyeptiInMoh from "./VyeptiInMoh";
import IvInfusion from "./IvInfusion";
import Summary from "./Summary";
import MddReductions from "./MddReductions";
import ResponderRates50 from "./ResponderRates50";
import Ashina from "./Ashina";
import ResponderRates75 from "./ResponderRates75";
import FastResponderRates from "./FastResponderRates";
import FastHoursData from "./FastHoursData";
import SustainedMidas from "./SustainedMidas";
import SustainedYearData from "./SustainedYearData";
import MohReductions from "./MohReductions";
import MohAcute from "./MohAcute";
import MohHit from "./MohHit";
import Sitemap from "./Sitemap";
import {
    BASICS, BASICS_1, BASICS_2, BASICS_3, BASICS_4, BASICS_5,
    EFFECTIVE_1,
    EFFECTIVE_2,
    EFFECTIVE_3, EFFECTIVE_4,
    EFFECTIVE_VYEPTI, EXTRA, FAST_1, FAST_2,
    HOMEPAGE,
    IV, IV_1, LONG_LASTING_1, LONG_LASTING_2, MEDICAL_HEADACHE_1, MEDICAL_HEADACHE_2, MEDICAL_HEADACHE_3,
    PATIENT, SITEMAP,
    SUMMARY, TOLERABILITY,
    VYEPTI_MOH
} from "../settings";
import IvOne from "./IvOne";
import Tolerability from "./Tolerability";
import Extra from "./Extra";


function MainApp() {
    const {page} = useSelector(state => state.main);
    // Page View (Default)
    switch (page) {
        case HOMEPAGE:
            return (
                <HomePage/>
            );
        case BASICS:
        case EFFECTIVE_VYEPTI:
            return (
                <ThePowerOfVyepti/>
            );
        case PATIENT:
            return (
                <ImpactedPatient/>
            );
        case VYEPTI_MOH:
            return (
                <VyeptiInMoh/>
            );
        case BASICS_4:
        case IV:
            return (
                <IvInfusion/>
            );
        case IV_1:
            return (
                <IvOne/>
            );
        case SUMMARY:
            return (
                <Summary/>
            );
        case BASICS_1:
        case EFFECTIVE_1:
            return (
                <MddReductions/>
            );
        case BASICS_2:
        case EFFECTIVE_2:
            return (
                <ResponderRates50/>
            );
        case EFFECTIVE_3:
            return (
                <Ashina/>
            );
        case EFFECTIVE_4:
            return (
                <ResponderRates75/>
            );
        case BASICS_3:
        case FAST_1:
            return (
                <FastResponderRates/>
            );
        case FAST_2:
            return (
                <FastHoursData/>
            );
        case LONG_LASTING_1:
            return (
                <SustainedMidas/>
            );
        case LONG_LASTING_2:
            return (
                <SustainedYearData/>
            );
        case MEDICAL_HEADACHE_1:
            return (
                <MohReductions/>
            );
        case MEDICAL_HEADACHE_2:
            return (
                <MohAcute/>
            );
        case MEDICAL_HEADACHE_3:
            return (
                <MohHit/>
            );
        case BASICS_5:
        case TOLERABILITY:
            return (
                <Tolerability/>
            );
        case SITEMAP:
            return (
                <Sitemap/>
            );
        case EXTRA:
            return (
                <Extra/>
            )
        default:
            return null;
    }
}

export default MainApp;