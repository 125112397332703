import {useDispatch, useSelector} from "react-redux";
import {closeFullReferenceModal, fullReferenceModalToggle, referenceModalToggle} from "../../actions";
import closeIcon from '../../images/teal-close-icon.png'
import backIcon from "../../images/back-icon.png";
import {SITEMAP} from "../../settings";
import React from "react";


function FullReferenceModal() {
    const {fullReferenceModalOpen, page} = useSelector(state => state.main);
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(closeFullReferenceModal());
    };
    const handleReferenceModalOpen = () => {
        if(page !== SITEMAP){
            dispatch(referenceModalToggle());
        }else{
            dispatch(fullReferenceModalToggle());
        }
    };
    return (
        <div id="ref-modal" className={`modal modal--ref  ${fullReferenceModalOpen ? 'visible' : ''}`}>
            <div className="modal__card modal__card--ref">
                <button onClick={handleClose} className="modal__close-btn">
                    <img src={closeIcon} alt="teal close icon" />
                </button>

                {page !== SITEMAP &&
                <button onClick={handleReferenceModalOpen} className="back-btn align-bottom">
                    <img src={backIcon} alt="back icon" />
                </button>
                }

                <div className="modal-container">
                    <div className="modal-heading">
                        <div className="leaf">
                            <h3>Täydellinen<br></br> kirjallisuusluettelo</h3>
                        </div>
                    </div>
                    <ul className='reference-list-full'>
                        <li>1. Ashina et al. <em>Cephalalgia</em> 2020 Mar;40(3):241-254.</li>
                        <li>2. Lipton et al. <em>Neurology</em> 2020;94;e1365-e1377.</li>
                        <li>3. Kudrow et al. <em>BMC Neurology</em> (2021) 21:126</li>
                        <li>4. Marmura et al. <em>Headache.</em> 2021 Oct;61(9):1421-1431.</li>
                        <li>5. Silberstein et al. <em>The Journal of Headache and Pain</em> (2020) 21:120.</li>
                        <li>6. Vyepti® valmisteyhteenveto.</li>
                        <li>7. Garcia-Martinez et al. <em>Journal of Pharmacology and Experimental Therapeutics July</em> 2020, 374 (1) 93-103.</li>
                        <li>8. Smith et al. <em>The Journal of Headache and Pain</em> (2021) 22:16.</li>
                        <li>9. Diener et al. <em>Headache</em> 2021 Jan;61(1):125-136.</li>
                        <li>10. Silberstein et al. <em>The Journal of Headache and Pain volume 21, Article number: 120</em> (2020) (Supplementary).</li>
                        <li>11. Winner et al <em>JAMA</em>. 2021;325(23):2348-2356.</li>
                        <li>12. Headache Classification Committee of the International Headache Society (HIS),
                            3rd edition, <em>Cephalalgia</em>. 2018 Jan;38(1):1-211.</li>
                        <li>13. Sun-Edelstein C et al <em>CNS Drugs</em> 2021 May;35(5):545-565.</li>
                        <li>14. Diener H-C et al <em>Nat Rev Neurology</em> 2016 Oct;12(10):575-83.</li>
                        <li>15. Houts CR et al. <em>Headache</em> 2020;60:28-39.</li>
                        <li>16. Diener H-C et al <em>Eur J Neurology</em> 2020 Jul;27(7):1102-1116.</li>
                        <li>17. Smith et al <em>Clinical Therapeutics/Volume 42, Number 12</em>, 2020.</li>
                        <li>18. Ashina <em>N Engl J Med</em> 2020;383:1866-76.</li>
                        <li>19. Dodick DW et al. <em>Headache</em> 2020;60:2220-2231.</li>
                    </ul>

                </div>
            </div>
        </div>
    )
}

export default FullReferenceModal;