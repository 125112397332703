import logoVyepti from '../images/vyepti_logo_fix.png';
import fastIcon from '../images/fast-background-icon.png';
import noteIcon from '../images/page10/note-icon.png';
import chronicMigraineChart from '../images/page10/chronic-migraine-chart_slide20_v2.png'
import episodicMigraineChart from '../images/page10/episodic-migraine-chart_slide21.png'
import React from "react";
import '../css/FastResponderRates.scss';
import {useDispatch, useSelector} from "react-redux";
import {changeView} from "../actions";


function FastResponderRates() {
    const dispatch = useDispatch();
    const {view} = useSelector(state => state.fastResponderRates);

    const handleViewChange = (_view) => {
        let payload = {
            view: _view
        }
        dispatch(changeView(payload));
    };


    return (
        <main className="fade-in background react-page-10">
            <div className="wrapper">
                <img className="background-icon"
                     src={fastIcon} alt="background icon" />
                    <h2 className="title title--margin">
                        Vyepti®n teho alkaa 1. päivänä annostelun jälkeen<sup>1,2</sup>
                    </h2>
                    <div>
                        <div className="tab">
                            <button onClick={() => handleViewChange(1)} id="chronic-btn" className={`tab__btn text--teal ${view === 1 ? 'tab__btn--active' : ''}`}>
                                VASTE KROONISESSA MIGREENISSÄ
                            </button>
                            <button onClick={() => handleViewChange(2)} id="episodic-btn" className={`tab__btn text--steel ${view === 2 ? 'tab__btn--active' : ''}`}>
                                VASTE EPISODISESSA MIGREENISSÄ
                            </button>
                        </div>
                        {view === 1 &&
                        <h3 className="subtitle subtitle--margin">
                            Keskimääräinen päivittäisen migreenin esiintyvyys, krooninen migreeni*
                        </h3>
                        }
                        {view === 2 &&
                        <h3 className="subtitle subtitle--margin">
                            Keskimääräinen päivittäisen migreenin esiintyvyys, episodinen migreeni*
                        </h3>
                        }
                        <div className="chart-container">
                            <div className="chart-image">
                                <img id="chronic-img" className={`tab-image ${view === 1 ? 'tab-image--active' : ''}`}
                                     src={chronicMigraineChart} alt="chronic migraine chart" />
                                <img id="episodic-img" className={`tab-image ${view === 2 ? 'tab-image--active' : ''}`}
                                     src={episodicMigraineChart} alt="episodic migraine chart" />
                            </div>
                            <div className="chart-note chart-note--size">
                                <img className="chart-note__icon" src={noteIcon}
                                     alt="chart note icon" />
                                    <p className="chart-note__text">
                                        Vyepti®n nopean ja pitkäkestoisen estovaikutuksen ansiosta vaste on mahdollista arvioida 1. hoitokuukauden aikana<sup>1,2</sup>
                                    </p>
                            </div>
                        </div>
                        <p className={`chart-info chart-info--margin ${view === 1 ? 'chart-info-visible' : ''}`}>
                            Kuva muokattu lähteestä Dodick DW et al. <em>Headache</em> 2020;60:2220-2231.
                        </p>
                        <p className={`chart-info chart-info--margin ${view === 2 ? 'chart-info-visible' : ''}`}>
                            Kuva muokattu lähteestä Dodick DW et al. <em>Headache</em> 2020;60:2220-2231.
                        </p>
                    </div>
                    <ul className="footnote-list">
                        <li className="footnote-list__item">
                            *Viikkojen 1–4 arvot tarkoittavat migreenioireisten potilaiden keskimääräistä prosenttiosuutta päivässä kyseisellä viikolla. Normalisointi päivien keskiarvoksi kuukaudessa tehtiin kertomalla päivän prosenttiosuus 28:lla (aloitusjakso).<sup>19</sup>
                        </li>
                        <li className="footnote-list__item">
                            {/*<strong>BL</strong>, baseline, lähtötilanne; <strong>CM</strong> chronic migraine, krooninen migreeni; <strong>EM</strong>, episodic migraine, episodinen migreeni.*/}
                        </li>
                    </ul>
                    <img className="logo" src={logoVyepti}
                         alt="logo vyepti" />
            </div>
        </main>
    )
}

export default FastResponderRates;