export const api = {};

export const getQueryString = (params) => {
    return Object
        .keys(params)
        .map(k => {
            if (Array.isArray(params[k])) {
                return params[k]
                    .map(val => `${encodeURIComponent(k)}[]=${encodeURIComponent(val)}`)
                    .join('&')
            }

            return `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`
        })
        .join('&')
};

const processResponse = async (response) => {
    if (response.ok) {
        return await response.json();
    } else {
        let error = new Error("Error");
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            error.detail = await response.json();
        } else {
            error.message = await response.text();
        }
        throw error;
    }
};

const makeRequest = async (url, options) => {
    let response;
    try {
        response = await fetch(url, options);
    } catch (e) {
        let error = new Error("Error");
        let message = e.message;
        if (message === "Failed to fetch") {
            message = 'Failed to fetch';
        }
        error.message = message;
        throw error;
    }
    return await processResponse(response);
};

const getCookie = (name) => {
    if (!document.cookie) {
        return null;
    }
    const xsrfCookies = document.cookie.split(';')
        .map(c => c.trim())
        .filter(c => c.startsWith(name + '='));
    if (xsrfCookies.length === 0) {
        return null;
    }
    return decodeURIComponent(xsrfCookies[0].split('=')[1]);
};

api.get = async (url, params = null) => {
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
        }
    };
    if (params !== null) {
        url = url + '?' + getQueryString(params);
    }
    return await makeRequest(url, options);
};

api.post = async (url, data, isUpload=false) => {
    const csrfToken = getCookie('csrftoken');
    let headers = {'X-CSRFToken': csrfToken};
    if (!isUpload) {
        headers['Accept'] = 'application/json';
        headers['Content-Type'] = 'application/json;charset=UTF-8';
    }
    const options = {
        method: 'POST',
        headers: headers,
        // file upload data will be wrapped in data = new FormData()
        body: isUpload ? data : JSON.stringify(data)
    };
    return await makeRequest(url, options);
};
