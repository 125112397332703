import logoVyepti from '../images/vyepti_logo_fix.png';
import arrowDown from '../images/arrow_down_farma.svg';
import '../css/IV_1.scss';
import React from "react";

function IvOne() {

    return (
        <main className="fade-in react-page-18 background">
            <div className="wrapper">
                <h2 className="title title--margin">
                    VYEPTI®N FARMAKOLOGISET OMINAISUUDET
                </h2>
                <div>
                    <h3 className="subtitle subtitle--margin">Vyepti® mahdollistaa nopean ja pitkäkestoisen tehon migreenin estohoidossa<sup>1,2</sup></h3>

                    <div className="grid">
                        <div className="grid__column grid__column--margin">
                            <div className='iv-card'>
                                <div className='iv-card__ball'>
                                    <p className='ball__text'>Selektiivinen<br></br> sitoutuminen</p>
                                </div>
                                <div className='iv-card__text'>
                                    <p>Korkea CGRP-spesifisyys<sup>7</sup></p>
                                    <p><br></br></p>

                                </div>
                                <div className='arrow-container'>
                                    <img width="50px" src={arrowDown} />
                                </div>
                                <div className='iv-card__bottom'>
                                    Ei off-target-aktiivisuutta<sup>7</sup>
                                </div>
                            </div>

                        </div>
                        <div className="grid__column grid__column--margin">
                            <div className='iv-card'>
                                <div className='iv-card__ball'>
                                    <p className='ball__text'><span className='large-text'>Vahva</span><br></br> sitoutuminen</p>
                                </div>
                                <div className='iv-card__text'>
                                   Suuri affiniteetti CGRP-ligandiin<br></br>(α 4 pM, β 3 pM Kd)<sup>6,7</sup>
                                </div>
                                <div className='arrow-container'>
                                        <img width="50px" src={arrowDown} />
                                </div>
                                <div className='iv-card__bottom'>
                                    Teho säilyy 12 viikon <br></br>annosteluvälin ajan<sup>1</sup>
                                </div>
                            </div>
                        </div>
                        <div className="grid__column grid__column--margin">
                            <div className='iv-card'>
                                <div className='iv-card__ball'>
                                    <p className='ball__text'><span className='large-text'>100</span> %<br></br> hyötyosuus</p>
                                </div>
                                <div className='iv-card__text'>
                                    <p>
                                        Mahdollistaa nopean CGRP-eston<sup>2</sup>
                                    </p>
                                    <p><br></br></p>
                                </div>
                                <div className='arrow-container'>
                                        <img width="50px" src={arrowDown} />
                                </div>
                                <div className='iv-card__bottom'>
                                    Tehoaa migreeniin jo 1. päivänä <br></br>annostelun jälkeen<sup>2</sup>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ul className="footnote-list">
                          <li className='footnote-list__item'>
                              <strong>CGRP</strong>, calcitonin gene-related peptide, kalsitoniinigeeniin liittyvä peptidi

                        </li>
                    </ul>
                    <img className="logo" src={logoVyepti}
                         alt="logo vyepti" />
                </div>
            </div>
        </main>
    )
}

export default IvOne;
