import {
    EFFECT_CHANGE_REQUESTED,
    PAGE_CHANGE_REQUESTED,
    OPEN_MOH_MODAL,
    CLOSE_MOH_MODAL,
    TOGGLE_SAFETY_MODAL,
    CLOSE_SAFETY_MODAL,
    CLOSE_SAFETY_MODAL_INNER,
    OPEN_SAFETY_MODAL_INNER,
    CLOSE_REFERENCE_MODAL,
    OPEN_REFERENCE_MODAL,
    TOGGLE_REFERENCE_MODAL,
    TOGGLE_FULL_REFERENCE_MODAL,
    CLOSE_FULL_REFERENCE_MODAL,
    OPEN_FULL_REFERENCE_MODAL,
    TOGGLE_REVIEW_BUTTONS, SET_SESSION_ID,
    TOGGLE_PRODUCT_INFORMATION_MODAL,
    CLOSE_PRODUCT_INFORMATION_MODAL
} from "../actions/types";

const initialState = {
    sessionId: null,
    page: 0,
    pageEffects: null,
    mohModalOpen: false,
    safetyModalOpen: false,
    safetyModalInnerOpen: false,
    referenceModalOpen: false,
    fullReferenceModalOpen: false,
    reviewButtonsOpen: false,
    productInformationModalOpen: false,
};

function mainReducer(state = initialState, action) {
    // noinspection JSRedundantSwitchStatement
    switch (action.type) {
        case EFFECT_CHANGE_REQUESTED:
            return Object.assign({}, state, {
                pageEffects: action.payload.page
            });
        case PAGE_CHANGE_REQUESTED:
            return Object.assign({}, state, {
                page: action.payload.page,
                pageEffects: null,
                reviewButtonsOpen: false,
                mohModalOpen: false,
                safetyModalOpen: false,
                safetyModalInnerOpen: false,
                referenceModalOpen: false,
                fullReferenceModalOpen: false,
                productInformationModalOpen: false,
            });
        case OPEN_MOH_MODAL:
            return Object.assign({}, state, {
                mohModalOpen: true,
                safetyModalOpen: false,
                safetyModalInnerOpen: false,
                referenceModalOpen: false
            });
        case TOGGLE_PRODUCT_INFORMATION_MODAL:
            return Object.assign({}, state, {
                productInformationModalOpen: !state.productInformationModalOpen,
            });
        case CLOSE_PRODUCT_INFORMATION_MODAL:
            return Object.assign({}, state, {
                productInformationModalOpen: false,
            });
        case CLOSE_MOH_MODAL:
            return Object.assign({}, state, {
                mohModalOpen: false,
            });
        case TOGGLE_SAFETY_MODAL:
            return Object.assign({}, state, {
                safetyModalOpen: !state.safetyModalOpen,
                mohModalOpen: false,
                safetyModalInnerOpen: false,
                referenceModalOpen: false
            });
        case CLOSE_SAFETY_MODAL:
            return Object.assign({}, state, {
                safetyModalOpen: false,
            });
        case CLOSE_SAFETY_MODAL_INNER:
            return Object.assign({}, state, {
                safetyModalInnerOpen: false,
            });
        case OPEN_SAFETY_MODAL_INNER:
            return Object.assign({}, state, {
                safetyModalInnerOpen: true,
                mohModalOpen: false,
                referenceModalOpen: false
            });
        case CLOSE_REFERENCE_MODAL:
            return Object.assign({}, state, {
                referenceModalOpen: false,
            });
        case OPEN_REFERENCE_MODAL:
            return Object.assign({}, state, {
                referenceModalOpen: true,
                mohModalOpen: false,
                safetyModalOpen: false,
                safetyModalInnerOpen: false,
            });
        case OPEN_FULL_REFERENCE_MODAL:
            return Object.assign({}, state, {
                referenceModalOpen: false,
                mohModalOpen: false,
                safetyModalOpen: false,
                safetyModalInnerOpen: false,
                fullReferenceModalOpen: true,
            });
        case TOGGLE_REFERENCE_MODAL:
            return Object.assign({}, state, {
                referenceModalOpen: !state.referenceModalOpen,
                mohModalOpen: false,
                safetyModalOpen: false,
                safetyModalInnerOpen: false,
                fullReferenceModalOpen: false,
            });
        case TOGGLE_FULL_REFERENCE_MODAL:
            return Object.assign({}, state, {
                fullReferenceModalOpen: !state.fullReferenceModalOpen,
                referenceModalOpen: false,
                mohModalOpen: false,
                safetyModalOpen: false,
                safetyModalInnerOpen: false,
            });
        case CLOSE_FULL_REFERENCE_MODAL:
            return Object.assign({}, state, {
                fullReferenceModalOpen: false,
            });
        case TOGGLE_REVIEW_BUTTONS:
            return Object.assign({}, state, {
                reviewButtonsOpen: !state.reviewButtonsOpen,
            });
        case SET_SESSION_ID:
            return Object.assign({}, state, {
                sessionId: action.payload.sessionId,
            });
        default:
            return state;
    }
}

export default mainReducer;