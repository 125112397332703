import logoVyepti from '../images/vyepti_logo_fix.png';
import efficaciousIcon from '../images/efficacious-background-icon.png';
import graphFrame from '../images/page7/graph-frame_slide16.png'
import noteIcon from '../images/page7/note-icon.png'
import React from "react";
import '../css/ResponderRates50.scss';


function ResponderRates50() {
    return (
        <main className="fade-in background react-page-7">
            <div className="wrapper">
                <img className="background-icon"
                     src={efficaciousIcon}
                     alt="background icon" />
                    <h2 className="title title--margin">
                        58 % kroonisista migreenipotilaista saivat vasteen<sup>2</sup>
                    </h2>
                    <div>
                        <h3 className="subtitle">≥50 % HOITOVASTEEN SAANEET</h3>
                        <div className="chart-container">
                            <div className="graph">
                                <div className="graph-container inverted">
                                    <div className="graph-bar inverted teal graph-bar-1">
                                        57.6
                                        <div>***</div>
                                    </div>
                                    <div className="graph-bar inverted steal graph-bar-2">
                                        61.4
                                        <div>***</div>
                                    </div>
                                    <div className="graph-bar inverted gray graph-bar-3">
                                        39.3
                                    </div>
                                </div>
                                <img className="chart-image" src={graphFrame} alt="migraine frequency chart" />

                            </div>
                            <div className="chart-note chart-note--size">
                                <img className="chart-note__icon" src={noteIcon} alt="chart note icon" />
                                    <p className="chart-note__text">
                                        4 viikon kuluessa ensimmäisestä  Vyepti®-infuusiosta kohtauslääkkeiden käyttö väheni ja vaikutus säilyi 6 kuukautta<sup>4, 10†</sup>
                                    </p>
                            </div>
                        </div>
                        <p className="chart-info chart-info--margin">
                            Kuva muokattu lähteestä Lipton et al. <em>Neurology</em> 2020;94;e1365-e1377.
                        </p>
                    </div>
                    <ul className="footnote-list">
                        <li className="footnote-list__item">
                        †12 viikon analyysissä akuuttilääkettä vaativien migreenipäivien määrä kuukaudessa väheni lähtötilanteesta kumpaakin Vyepti®-annosta saaneilla (100 mg, -3,3 päivää, p &lt; 0,0001; 300 mg, -3,5 päivää, p &lt; 0,0001; lume, -1,9 päivää), ja vaikutus säilyi tai suureni toisen annosvälin aikana. *** p &lt; 0,0001 lumeeseen verrattuna<sup>10</sup>
                        </li>
{/*                        <li className="footnote-list__item">*/}
{/*<strong>CGRP</strong>, calcitonin gene-related peptide, kalsitoniinigeeniin liittyvä peptidi; <strong>MMD</strong>, monthly migraine day, migreenipäiviä kuukaudessa; <strong>MRR</strong>, migraine responder rate, migreenivaste; <strong>SD</strong>, standard deviation, keskihajonta*/}
{/*                        </li>*/}
                    </ul>
                    <img className="logo" src={logoVyepti} alt="logo vyepti" />
            </div>
        </main>
    )
}

export default ResponderRates50;