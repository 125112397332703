import logoVyepti from '../images/vyepti_logo_fix.png';
import efficaciousIcon from '../images/efficacious-icon.png';
import fastIcon from '../images/fast-icon.png';
import sustainedIcon from '../images/sustained-icon.png';
import plusIcon from '../images/light-plus-icon.png';
import React from "react";
import '../css/ThePowerOfVyepti.scss';
import {useDispatch} from "react-redux";
import {moveToNextPage} from "../actions";
import {EFFECTIVE_1, FAST_1, LONG_LASTING_1} from "../settings";


function ThePowerOfVyepti() {
    const dispatch = useDispatch();
    // const {page} = useSelector(state => state.main);

    const handlePageChange = (page) => {
        let payload = {
            page: page
        }
        dispatch(moveToNextPage(payload));
    };

    return (
        <main className="fade-in background background--big react-page-1">
            <div className="wrapper">
                <h2 className="title title--margin">
                    VYEPTI® ON TEHOKAS MIGREENIN estohoito
                </h2>
                <div>
                    <div className="grid">
                        <div className="grid__column grid__column--margin">
                            <div id="efficacious-card" className="card__face--static">
                                <div className="card__header">
                                    <div className="card__icon-container">
                                        <img className="card__icon card__icon--size"
                                             src={efficaciousIcon}
                                             alt="efficacious icon"/>
                                    </div>
                                    <p className="card__title card__title--margin text--teal">
                                        TEHOKAS
                                    </p>
                                </div>
                                <p className="card__description">
                                    Vyepti® vähentää merkitsevästi migreenipäiviä;
                                    58&nbsp;% potilaista sai vasteen<sup>2</sup>*
                                </p>
                                <button onClick={() => handlePageChange(EFFECTIVE_1)} className="flip__btn flip__btn--padding bg--teal">
                                    <span className="flip__text">Lisätietoa</span>
                                    <img className="flip__icon flip__icon--size"
                                         src={plusIcon} alt="light plus icon" />
                                </button>
                            </div>
                        </div>
                        <div className="grid__column grid__column--margin">
                            <div id="fast-card" className="card__face--static">
                                <div className="card__header">
                                    <div className="card__icon-container">
                                        <img className="card__icon card__icon--size icon-fast"
                                             src={fastIcon}
                                             alt="fast icon" />
                                    </div>
                                    <p className="card__title card__title--margin text--steel">
                                        NOPEA
                                    </p>
                                </div>
                                <p className="card__description">
                                    Vyepti® tehoaa jo 1. päivänä infuusion jälkeen<sup>1,2</sup>
                                </p>
                                <button onClick={() => handlePageChange(FAST_1)} className="flip__btn flip__btn--padding bg--steel">
                                    <span className="flip__text">Lisätietoa</span>
                                    <img className="flip__icon flip__icon--size"
                                         src={ plusIcon } alt="light plus icon"/>
                                </button>
                            </div>
                        </div>
                        <div className="grid__column grid__column--margin">
                            <div id="sustained-card" className="card__face--static">
                                <div className="card__header">
                                    <div className="card__icon-container">
                                        <img className="card__icon card__icon--size"
                                             src={sustainedIcon}
                                             alt="sustained icon" />
                                    </div>
                                    <p className="card__title card__title--margin text--red">
                                        PITKÄVAIKUTTEINEN
                                    </p>
                                </div>
                                <p className="card__description">
                                    Yli 2/3 Vyepti®-potilaista raportoivat toimintakyvyn parantumisesta 2 vuoden seurannan aikana.<sup>3</sup>
                                </p>
                                <button onClick={() => handlePageChange(LONG_LASTING_1)} className="flip__btn flip__btn--padding bg--red">
                                    <span className="flip__text">Lisätietoa</span>
                                    <img className="flip__icon flip__icon--size"
                                         src={ plusIcon } alt="light plus icon" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <ul className="footnote-list">
                        <li className="footnote-list__item">
                            *Vaste tarkoittaa kuukausittaisten migreenipäivien 50 % vähenemää
                        </li>
                    </ul>
                    <img className="logo" src={logoVyepti}
                         alt="logo vyepti"/>
                </div>
            </div>
        </main>
    )
}

export default ThePowerOfVyepti;