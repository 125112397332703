import React from "react";
import {useSelector} from "react-redux";
import {
    EFFECTIVE_1,
    EFFECTIVE_2,
    EFFECTIVE_3, EFFECTIVE_4,
    EFFECTIVE_VYEPTI, FAST_1, FAST_2,
    IV, LONG_LASTING_1, LONG_LASTING_2, MEDICAL_HEADACHE_1, MEDICAL_HEADACHE_2, MEDICAL_HEADACHE_3,
    PATIENT,
    SUMMARY,
    VYEPTI_MOH, IV_1, TOLERABILITY, BASICS, BASICS_4, BASICS_1, BASICS_2, BASICS_3, BASICS_5
} from "../../settings";


function ReferenceList() {
    const {page} = useSelector(state => state.main);
    // Page View (Default)
    switch (page) {
        case BASICS:
        case EFFECTIVE_VYEPTI:
            return (
                <ul className="ref-list">
                    <li className="ref-list__item">
                        1. Ashina et al. <em>Cephalalgia</em> 2020 Mar;40(3):241-254.
                    </li>
                    <li className="ref-list__item">
                        2. Lipton et al. <em>Neurology</em> 2020;94;e1365-e1377.
                    </li>
                    <li className="ref-list__item">
                        3. Kudrow et al. <em>BMC Neurology</em> (2021) 21:126.
                    </li>
                    {/*<li className="ref-list__item">*/}
                    {/*    6. Silberstein S et al. <em>J Headache Pain</em>. 2020.*/}
                    {/*</li>*/}
                    <li className="ref-list__item">
                        11. Winner et al <em>JAMA</em>. 2021;325(23):2348-2356.
                    </li>
                </ul>
            );
        case PATIENT:
            return (
                <ul className="ref-list">
                    <li className="ref-list__item">9. Diener et al. <em>Headache</em> 2021 Jan;61(1):125-136.</li>
                    <li className="ref-list__item">12. Headache Classification Committee of the International Headache
                        Society (IHS), 3rd edition. <em>Cephalalgia</em>. 2018.
                    </li>
                    <li className="ref-list__item">13. Sun-Edelstein C et al. <em>CNS Drugs</em> 2021 May;35(5):545-565.</li>
                    <li className="ref-list__item">14. Diener H-C et al Nat Rev <em>Neurology 12</em>, 2016 Oct;12(10):575-83.</li>
                    <li className="ref-list__item">16. Diener H-C et al <em>European Journal of Neurology</em> 2020 Jul;27(7):1102-1116.</li>
                </ul>
            );
        case VYEPTI_MOH:
            return (
                <ul className="ref-list">
                    {/*<li className="ref-list__item">*/}
                    {/*    1. Ashina et al. <em>Cephalalgia</em> 2020 Mar;40(3):241-254.*/}
                    {/*</li>*/}
                    {/*<li className="ref-list__item">*/}
                    {/*    2. Lipton et al. <em>Neurology</em> 2020;94;e1365-e1377.*/}
                    {/*</li>*/}
                    <li className="ref-list__item">
                        4. Marmura et al. <em>Headache</em>. 2021 Oct;61(9):1421-1431.
                    </li>
                    <li className="ref-list__item">
                        6. Vyepti® valmisteyhteenveto.
                    </li>
                    <li className="ref-list__item">
                        9. Diener et al. <em>Headache</em> 2021 Jan;61(1):125-136.
                    </li>
                    <li className="ref-list__item">
                        11. Winner et al <em>JAMA</em>. 2021;325(23):2348-2356.
                    </li>
                </ul>
            );
        case BASICS_4:
        case IV:
            return (
                <ul className="ref-list">
                    <li className="ref-list__item">
                        6. Vyepti® valmisteyhteenveto.
                    </li>
                </ul>
            );
        case SUMMARY:
            return(
                <ul className="ref-list">
                    <li className="ref-list__item">
                        1. Ashina et al. <em>Cephalalgia</em> 2020 Mar;40(3):241-254.
                    </li>
                    <li className="ref-list__item">
                        2. Lipton et al. <em>Neurology</em> 2020;94;e1365-e1377.
                    </li>
                    <li className="ref-list__item">
                        3. Kudrow et al. <em>BMC Neurology</em> (2021) 21:126.
                    </li>
                    <li className="ref-list__item">
                        4. Marmura et al. <em>Headache</em>. 2021 Oct;61(9):1421-1431.
                    </li>
                    <li className="ref-list__item">
                        6. Vyepti® valmisteyhteenveto.
                    </li>
                    <li className="ref-list__item">
                        9. Diener et al. <em>Headache</em> 2021 Jan;61(1):125-136.
                    </li>
                </ul>
            );
        case BASICS_1:
        case EFFECTIVE_1:
            return(
                <ul className="ref-list">
                    <li className="ref-list__item">1. Ashina et al. <em>Cephalalgia</em> 2020 Mar;40(3):241-254.</li>
                    <li className="ref-list__item">2. Lipton et al. <em>Neurology</em> 2020;94;e1365-e1377.</li>
                </ul>
            );
        case BASICS_2:
        case EFFECTIVE_2:
            return(
                <ul className="ref-list">
                    <li className="ref-list__item">2. Lipton et al. <em>Neurology</em> 2020;94;e1365-e1377.</li>
                    <li className="ref-list__item">4. Marmura et al. <em>Headache</em>. 2021 Oct;61(9):1421-1431.</li>
                    <li className="ref-list__item">10. Silberstein et al. <em>The Journal of Headache and Pain volume 21, Article number: 120</em> (2020). </li>
                </ul>
            );
        case EFFECTIVE_3:
            return(
                <ul className="ref-list">
                    <li className="ref-list__item">18. Ashina M. <em>Migraine. N Engl J Med</em> 2020;383:1866-76. Supplementary Appendix.</li>
                </ul>
            );
        case EFFECTIVE_4:
            return(
                <ul className="ref-list">
                    <li className="ref-list__item">2. Lipton et al. <em>Neurology</em> 2020;94;e1365-e1377.</li>
                    <li className="ref-list__item">3. Kudrow et al. <em>BMC Neurology</em> (2021) 21:126.</li>
                </ul>
            );
        case BASICS_3:
        case FAST_1:
            return(
                <ul className="ref-list">
                    <li className="ref-list__item">1. Lipton et al. <em>Neurology</em> 2020;94;e1365-e1377.</li>
                    <li className="ref-list__item">2. Ashina et al. <em>Cephalalgia</em> 2020 Mar;40(3):241-254.</li>
                    <li className="ref-list__item">19. Dodick DW et al. <em>Headache</em> 2020;60:2220-2231.</li>
                </ul>
            );
        case FAST_2:
            return(
                <ul className="ref-list">
                    <li className="ref-list__item">11. Winner et al <em>JAMA</em>. 2021;325(23):2348-2356.</li>
                </ul>
            );
        case LONG_LASTING_1:
            return(
                <ul className="ref-list">
                    <li className="ref-list__item">3. Kudrow et al. <em>BMC Neurology</em> (2021) 21:126.</li>
                </ul>
            );
        case LONG_LASTING_2:
            return(
                <ul className="ref-list">
                    <li className="ref-list__item">5. Silberstein et al. <em>The Journal of Headache and Pain</em> (2020) 21:120.</li>
                    <li className="ref-list__item">17. Smith et al <em>Clinical Therapeutics/Volume 42, Number 12</em>, 2020.</li>
                </ul>
            );
        case MEDICAL_HEADACHE_1:
            return(
                <ul className="ref-list">
                    <li className="ref-list__item">9. Diener et al. <em>Headache</em>. 2021 Jan;61(1):125-136.</li>
                </ul>
            );
        case MEDICAL_HEADACHE_2:
            return(
                <ul className="ref-list">
                    <li className="ref-list__item">
                        4. Marmura et al. <em>Headache</em>. 2021 Oct;61(9):1421-1431.
                    </li>
                </ul>
            );
        case MEDICAL_HEADACHE_3:
            return(
                <ul className="ref-list">
                    {/*<li className="ref-list__item">1. Diener H-C et al. Poster 20-1174-E. <em>American Headache*/}
                    {/*    Society</em>, 2020. San Diego, CA;*/}
                    {/*</li>*/}
                    <li className="ref-list__item">10. Silberstein et al. <em>The Journal of Headache and Pain volume 21, Article number: 120</em> (2020).</li>
                    <li className="ref-list__item">15. Houts CR et al. <em>Headache</em>. 2020 Jan;60(1):28-39.</li>
                </ul>
            );
        case IV_1:
            return(
                <ul className="ref-list">
                    <li className="ref-list__item">
                        1) Ashina et al. <em>Cephalalgia</em> 2020 Mar;40(3):241-254.
                    </li>
                    <li className="ref-list__item">
                        2) Lipton et al. <em>Neurology</em> 2020;94;e1365-e1377.
                    </li>
                    <li className="ref-list__item">
                        6) Vyepti® valmisteyhteenveto.
                    </li>
                    <li className="ref-list__item">
                        7) Garcia-Martinez et al. <em>Journal of Pharmacology and Experimental Therapeutics July</em> 2020, 374 (1) 93-103.
                    </li>
                </ul>
            );
        case BASICS_5:
        case TOLERABILITY:
            return(
                <ul className="ref-list">
                    <li className="ref-list__item">
                        6) Vyepti® valmisteyhteenveto.
                    </li>
                    <li className="ref-list__item">
                        8) Smith et al. <em>The Journal of Headache and Pain </em>(2021) 22:16.
                    </li>
                </ul>
            );
        default:
            return null;
    }
}

export default ReferenceList;