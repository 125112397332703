import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {moveToNextPage, toggleReviewButtons} from "../../actions";
import {ReactComponent as ArrowRight} from '../../images/chevron-right-solid.svg';
import {ReactComponent as ArrowLeft} from '../../images/chevron-left-solid.svg';
import {
    EFFECTIVE_VYEPTI,
    IV,
    MEDICAL_HEADACHE_1,
    PATIENT,
    SUMMARY,
    VYEPTI_MOH,
    TOLERABILITY,
    EFFECTIVE_1,
    EFFECTIVE_2,
    EFFECTIVE_3,
    HOMEPAGE,
    EFFECTIVE_4,
    FAST_1,
    FAST_2,
    LONG_LASTING_1,
    LONG_LASTING_2,
    MEDICAL_HEADACHE_2,
    MEDICAL_HEADACHE_3,
    IV_1,
    SITEMAP,
    BASICS,
    BASICS_1,
    BASICS_2,
    BASICS_3,
    BASICS_4,
    BASICS_5,
} from "../../settings";


function TopBar() {
    const dispatch = useDispatch();
    const {page} = useSelector(state => state.main);

    const handlePageChange = (_page) => {
        let payload = {
            page: _page
        }
        dispatch(moveToNextPage(payload));
    };
    const handleMoveForward = (_page) => {
        let _custom_page;
        switch (page){
            case BASICS:
                _custom_page = BASICS_1
                break;
            case BASICS_1:
                _custom_page = BASICS_2
                break;
            case BASICS_2:
                _custom_page = BASICS_3
                break;
            case BASICS_3:
                _custom_page = BASICS_4
                break;
            case BASICS_4:
                _custom_page = BASICS_5
                break;
            case BASICS_5:
                _custom_page = BASICS
                break;
            case EFFECTIVE_1:
                _custom_page = EFFECTIVE_2
                break;
            case EFFECTIVE_VYEPTI:
                _custom_page = PATIENT
                break;
            case EFFECTIVE_2:
                _custom_page = EFFECTIVE_3
                break;
            case EFFECTIVE_3:
                _custom_page = EFFECTIVE_4
                break;
            case EFFECTIVE_4:
            case FAST_2:
            case LONG_LASTING_2:
                _custom_page = EFFECTIVE_VYEPTI
                break;
            case FAST_1:
                _custom_page = FAST_2
                break;
            case LONG_LASTING_1:
                _custom_page = LONG_LASTING_2
                break;
            case PATIENT:
            case MEDICAL_HEADACHE_3:
                _custom_page = VYEPTI_MOH
                break;
            case VYEPTI_MOH:
                _custom_page = MEDICAL_HEADACHE_1;
                break;
            case MEDICAL_HEADACHE_1:
                _custom_page = MEDICAL_HEADACHE_2;
                break;
            case MEDICAL_HEADACHE_2:
                _custom_page = MEDICAL_HEADACHE_3;
                break;
            case IV:
                _custom_page = IV_1;
                break;
            case IV_1:
                _custom_page = IV;
                break;
            case TOLERABILITY:
                _custom_page = SUMMARY;
                break;
            case SUMMARY:
            case SITEMAP:
                _custom_page = SITEMAP;
                break;
            default:
                _custom_page = HOMEPAGE
                break;
        }
        let payload = {
            page: _custom_page
        }
        dispatch(moveToNextPage(payload));
    };

    const handleMoveBackwards = (_page) => {
        let _custom_page;
        switch (page){
            case BASICS_1:
                _custom_page = BASICS
                break;
            case BASICS_2:
                _custom_page = BASICS_1
                break;
            case BASICS_3:
                _custom_page = BASICS_2
                break;
            case BASICS_4:
                _custom_page = BASICS_3
                break;
            case BASICS_5:
                _custom_page = BASICS_4
                break;
            case EFFECTIVE_1:
            case PATIENT:
            case LONG_LASTING_1:
            case FAST_1:
                _custom_page = EFFECTIVE_VYEPTI
                break;
            case EFFECTIVE_VYEPTI:
                _custom_page = HOMEPAGE
                break;
            case EFFECTIVE_2:
                _custom_page = EFFECTIVE_1
                break;
            case EFFECTIVE_3:
                _custom_page = EFFECTIVE_2
                break;
            case EFFECTIVE_4:
                _custom_page = EFFECTIVE_3
                break;
            case FAST_2:
                _custom_page = FAST_1
                break;
            case LONG_LASTING_2:
                _custom_page = LONG_LASTING_1
                break;
            case MEDICAL_HEADACHE_3:
                _custom_page = MEDICAL_HEADACHE_2
                break;
            case VYEPTI_MOH:
                _custom_page = PATIENT;
                break;
            case MEDICAL_HEADACHE_1:
                _custom_page = VYEPTI_MOH;
                break;
            case MEDICAL_HEADACHE_2:
                _custom_page = MEDICAL_HEADACHE_1;
                break;
            case IV:
                _custom_page = VYEPTI_MOH;
                break;
            case IV_1:
            case TOLERABILITY:
                _custom_page = IV;
                break;
            case SUMMARY:
                _custom_page = TOLERABILITY;
                break;
            case SITEMAP:
                _custom_page = SUMMARY;
                break;
            default:
                _custom_page = HOMEPAGE
                break;
        }
        let payload = {
            page: _custom_page
        }
        dispatch(moveToNextPage(payload));
    };
    const handleToggleReviewButtons = () => {
        dispatch(toggleReviewButtons());
    };

    let effectiveList = [EFFECTIVE_VYEPTI, EFFECTIVE_1, EFFECTIVE_2, EFFECTIVE_3,
        EFFECTIVE_4, FAST_1, FAST_2, LONG_LASTING_1, LONG_LASTING_2]
    let medicalHeadacheList = [VYEPTI_MOH, MEDICAL_HEADACHE_1, MEDICAL_HEADACHE_2, MEDICAL_HEADACHE_3]
    let ivList = [IV, IV_1]
    let basicsList = [BASICS, BASICS_1, BASICS_2, BASICS_3, BASICS_4, BASICS_5]

    return (
        <nav className="navbar">
            <div className="navbar-wrapper">
                <div className="navbar-item">
                    <div className="navbar-controls-container">
                        <div className="navbar-review-container">
                            <div onClick={handleToggleReviewButtons} className='review-inner-container'>
                                ...
                            </div>
                        </div>
                        <div className="navbar-movement-container">
                            <button onClick={() => handleMoveBackwards(page)} className='button-navbar-movement movement-left'>
                                <ArrowLeft/>
                            </button>
                            <button onClick={() => handleMoveForward(page)}  className='button-navbar-movement movement-right'>
                                <ArrowRight/>
                            </button>
                        </div>
                    </div>
                </div>
                <div onClick={() => handlePageChange(BASICS)} className={`navbar-item ${basicsList.includes(page) ? 'active-navigation' : ''}`}>
                    <div className="navbar-item-text">
                        <p>Perusteet</p>
                    </div>
                </div>
                <div onClick={() => handlePageChange(EFFECTIVE_VYEPTI)} className={`navbar-item ${effectiveList.includes(page) ? 'active-navigation' : ''}`}>
                    <div className="navbar-item-text">
                        <p>Tehokas Vyepti®</p>
                    </div>
                </div>
                <div onClick={() => handlePageChange(PATIENT)} className={`navbar-item ${page === PATIENT ? 'active-navigation' : ''}`}>
                    <div className="navbar-item-text">
                        <p>Potilas</p>
                    </div>
                </div>
                <div onClick={() => handlePageChange(VYEPTI_MOH)} className={`navbar-item ${medicalHeadacheList.includes(page)? 'active-navigation' : ''}`}>
                    <div className="navbar-item-text">
                        <p>Vyepti® ja MOH</p>
                    </div>
                </div>
                <div onClick={() => handlePageChange(IV)} className={`navbar-item ${ivList.includes(page) ? 'active-navigation' : ''}`}>
                    <div className="navbar-item-text">
                        <p>Laskimoinfuusio</p>
                    </div>
                </div>
                <div onClick={() => handlePageChange(TOLERABILITY)} className={`navbar-item ${page === TOLERABILITY ? 'active-navigation' : ''}`}>
                    <div className="navbar-item-text">
                        <p>Siedettävyys</p>
                    </div>
                </div>
                <div onClick={() => handlePageChange(SUMMARY)} className={`navbar-item ${page === SUMMARY ? 'active-navigation' : ''}`}>
                    <div className="navbar-item-text">
                        <p>Tiivistelmä</p>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default TopBar;