import './App.scss';
import React, {useEffect, useState} from "react";
import MainApp from "./components/MainApp";
import {useDispatch, useSelector} from "react-redux";
import {changePageEffects, setSessionId, sendReviewStatistics} from "./actions";
import TopBar from "./components/menus/TopBar";
import MohModal from "./components/modals/MohModal";
import SideBar from "./components/menus/SideBar";
import SafetyModal from "./components/modals/SafetyModal";
import SafetyModalInner from "./components/modals/SafetyModalInner";
import ReferenceModal from "./components/modals/ReferenceModal";
import FullReferenceModal from "./components/modals/FullReferenceModal";
import Timer from "./components/menus/Timer";
import {v4 as uuidv4} from 'uuid';
import ProductInformationModal from "./components/modals/ProductInformationModal";
import logoVyepti from './images/vyepti_logo_fix.png';
import {ACCESS} from "./settings";
import {useLocalStorage} from "./components/localStorage";

function App() {
  const {page, pageEffects, reviewButtonsOpen, sessionId} = useSelector(state => state.main);
  const dispatch = useDispatch();
  const [loggedIn, setLoggedIn] = useLocalStorage("loggedIn", "");
  const [password, setPassword] = useState("");
  const [review, setReview] = useState('');
  const [activePage, setActivePage] = useState(page);
  useEffect(() => {
      if (sessionId === null){
        let payload = {
            sessionId: uuidv4()
        }
        dispatch(setSessionId(payload))
      }
      if(pageEffects === null){
        let payload = {
            page
        }
        dispatch(changePageEffects(payload))
      }
  }, [page, pageEffects, sessionId,  dispatch]);

  const handleSetReview = (review) => {
    setReview(review)
    let payload = {
      activePage: page,
      review,
      sessionId
    }
    dispatch(sendReviewStatistics(payload))
  };

  const handleLoginAttempt = () => {
    if(password === ACCESS){
        setLoggedIn(true)
    }
  };


  if(page !== activePage){
      setActivePage(page);
      setReview('')
  }

  return (
    <div className="App">
        {page !== 0 &&
            <TopBar/>
        }
        {page !== 0 &&
            <SideBar/>
        }
        {loggedIn &&
            <MainApp/>
        }
        {!loggedIn &&
            <div className='log-in-container'>
                <div className='log-in-badge'>
                    <img src={logoVyepti} alt='Vyepti'/>
                    <form>
                        <input type="password" value={password} onChange={e => setPassword(e.target.value)} className='log-in-input' placeholder='Salasana'/>
                        <input onClick={() => handleLoginAttempt()} className='log-in-button' type="submit" value="Sisään" />
                    </form>
                </div>
            </div>
        }
        {page === 0 &&
        <div className="shape-container">
            <div className={`shape shape__darker bg--teal shape__darker${pageEffects === 0 ? '--moved' : ''}`}></div>
            <div className={`shape shape__dark bg--teal shape__dark${pageEffects === 0 ? '--moved' : ''}`}></div>
            <div className={`shape shape__light bg--teal shape__light${pageEffects === 0 ? '--moved' : ''}`}></div>
        </div>
        }

        <div className={`review-buttons-container ${reviewButtonsOpen ? 'visible' : ''}`}>
            <div className='review-button'>
                <div className="radio-button-container" onClick={() => handleSetReview('good')}>
                    <input readOnly={true} type="radio" value='good' name="review" checked={review === 'good'} />
                    <span className="checkmark blue"></span>
                </div>
            </div>
            <div className='review-button'>
                <div className="radio-button-container" onClick={() => handleSetReview('neutral')}>
                    <input readOnly={true} type="radio" value='neutral' name="review" checked={review === 'neutral'}  />
                    <span className="checkmark"></span>
                </div>
            </div>
            <div className='review-button'>
                <div className="radio-button-container" onClick={() => handleSetReview('bad')}>
                    <input readOnly={true} type="radio" value='bad'  name="review" checked={review === 'bad'} />
                    <span className="checkmark red"></span>
                </div>
            </div>
        </div>
        <MohModal/>
        <SafetyModal/>
        <SafetyModalInner/>
        <ReferenceModal/>
        <FullReferenceModal/>
        <ProductInformationModal/>
        <Timer/>
    </div>
  );
}

export default App;
