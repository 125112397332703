import logoVyepti from '../images/vyepti_logo_fix.png';
import efficaciousArrow from '../images/page3/efficacious-arrow.png'
import fastCircle from '../images/page3/fast-circle.png'
import fastArrow from '../images/page3/fast-arrow.png'
import sustainedBackHeads from '../images/page3/sustained-back-heads.png'
import sustainedFrontHead from '../images/page3/sustained-front-head.png'
import popUpIcon from '../images/pop-up-icon.png'
import React from "react";
import '../css/VyeptiInMoh.scss';
// import {useDispatch} from "react-redux";
// import {openMohModal} from "../actions";


function VyeptiInMoh() {
    // const dispatch = useDispatch();
    //
    // const handleOpenMohModal = () => {
    //     dispatch(openMohModal());
    // };

    return (
        <main className="fade-in react-page-3 background background--big">
            <div className="wrapper">
                <h2 className="title title--margin">
                    VYEPTI® ANTAA POTILAALLE MAHDOLLISUUDEN KATKAISTA LÄÄKEPÄÄNSÄRYN NOIDANKEHÄN<sup>*4,9</sup>
                </h2>
                <div>
                    <div className="grid">
                        <div className="grid__column grid__column--margin">
                            <div className="core-flow__header text--teal">TEHOKAS</div>
                            <div className="core-flow__image">
                                <img className="core-flow__background" src={efficaciousArrow}
                                     alt="efficacious arrow" />
                            </div>
                            <p className="core-flow__text">
                                Vyepti® vähensi migreenipäiviä potilailla, joilla on krooninen migreeni + lääkepäänsärky
                                <span className="core-flow__emphasis text--teal">&gt; 8 päivällä</span>
                                kuukaudessa<sup>†9</sup>
                            </p>
                        </div>
                        <div className="grid__column grid__column--margin">
                            <div className="core-flow__header text--steel">NOPEA</div>
                            <div className="core-flow__image">
                                <img className="core-flow__background" src={fastCircle}
                                     alt="fast circle" /><img className="core-flow__fast-arrow animation"
                                                            src={fastArrow} alt="fast arrow" />
                            </div>
                            <p className="core-flow__text">
                                Vyepti®-hoitoa saaneet käyttivät 50 % vähemmän kohtauslääkkeitä
                                <span className="core-flow__emphasis text--steel">24 tunnin sisällä<sup>‡11</sup></span>
                            </p>
                        </div>

                        <div className="grid__column grid__column--margin">
                            <div className="core-flow__header text--red">Pitkäkestoinen TEHO</div>
                            <div className="core-flow__image">
                                <img className="core-flow__background" src={sustainedBackHeads}
                                     alt="sustained back heads" /><img className="core-flow__sustained-head animation"
                                                                     src={sustainedFrontHead}
                                                                     alt="sustained front head" />
                                    {/*<button className="pop-up-btn">*/}
                                    {/*    <img src={popUpIcon}*/}
                                    {/*         alt="pop-up icon" />*/}
                                    {/*</button>*/}
                            </div>
                            <p className="core-flow__text">
                                Vyepti®n teho säilyy koko <span className="core-flow__emphasis text--red">3 kuukauden</span> annosteluvälin ajan<sup>6</sup>
                            </p>
                        </div>
                    </div>
                    {/*<button onClick={handleOpenMohModal} className="moh-btn">*/}
                    {/*    Vyepti<sup>TM</sup> – Ainoa CGRP:n estäjä, jota on tutkittu MOH-potilailla*/}
                    {/*    with MOH*/}
                    {/*</button>*/}
                    <ul className="footnote-list">
                        <li className="footnote-list__item">
                            *Noidankehän katkaisu tarkoittaa sitä, että potilaan akuuttilääkkeen käyttö ei enää täytä lääkepäänsäryn ICHD-3-kriteereitä
                            ja päänsärkypäivien määrä jää kroonisen migreenin kynnysarvon (≥ 15 päivää kuukaudessa kolmen kuukauden ajan) alle.<sup>9</sup>
                            †Lumelääke vähensi migreenipäiviä 5:llä. Lääkepäänsärkyä sairastavilla oli keskimäärin 16,7
                            migreenipäivää 28 vuorokauden alkujaksolla<sup>9</sup>. ‡Hoito aloitettiin migreenikohtauksen aikana. Akuuttilääkkeen käyttö 24 tunnin
                            kuluessa infuusiosta: 31,5 % Vyepti®-hoitoa (100 mg) ja 59,9 % lumelääkettä saaneista, p &lt; 0,001<sup>11</sup>
                        </li>
                        {/*<li className="footnote-list__item">*/}
                        {/*    <strong>CM</strong>, chronic migraine, krooninen migreeni; <strong>MMD</strong>, monthly migraine day, migreenipäiviä kuukaudessa; <strong>MOH</strong>, medication overuse headache, lääkepäänsärky*/}
                        {/*</li>*/}
                    </ul>
                    <img className="logo" src={logoVyepti}
                         alt="logo vyepti" />
                </div>
            </div>
        </main>
    )
}

export default VyeptiInMoh;