import logoVyepti from '../images/vyepti_logo_fix.png';
import efficaciousIcon from '../images/efficacious-background-icon.png';
import graphFrame from '../images/page8/krooninen_migreeni_v4.png';
import React from "react";
import '../css/Ashina.scss';


function Ashina() {
    return (
        <main className="fade-in react-page-8 background">
            <div className="wrapper">
                <img className="background-icon"
                     src={efficaciousIcon}
                     alt="background icon" />
                    <h2 className="title title--margin">
                        Krooninen migreeni ja CGRP:n estäjät
                    </h2>
                    <div>
                        <h3 className="subtitle">
                            ≥50 % HOITOVASTEEN SAANEET, KROONINEN MIGREENI<sup>18</sup>
                        </h3>
                        <div className="chart-container">
                            <div className="graph">
                                <div className="graph-container inverted">
                                    <div className="graph-bar inverted steal graph-bar-1">
                                        57.6
                                    </div>
                                    <div className="graph-bar inverted teal graph-bar-2">
                                        61.4
                                    </div>
                                    <div className="graph-bar inverted gray graph-bar-3">
                                        39.3
                                    </div>
                                    <div className="bar-spacer"></div>
                                    <div className="graph-bar inverted graph-gray-1 graph-bar-4">
                                        39.9
                                    </div>
                                    <div className="graph-bar inverted graph-gray-2 graph-bar-5">
                                        41.2
                                    </div>
                                    <div className="graph-bar inverted gray graph-bar-6">
                                        23.5
                                    </div>
                                    <div className="bar-spacer"></div>
                                    <div className="graph-bar inverted graph-gray-1 graph-bar-7">
                                        40.8
                                    </div>
                                    <div className="graph-bar inverted graph-gray-2 graph-bar-8">
                                        37.6
                                    </div>
                                    <div className="graph-bar inverted gray graph-bar-9">
                                        18.1
                                    </div>
                                    <div className="bar-spacer"></div>
                                    <div className="graph-bar inverted graph-gray-1 graph-bar-10">
                                        27.6
                                    </div>
                                    <div className="graph-bar inverted graph-gray-2 graph-bar-11">
                                        27.5
                                    </div>
                                    <div className="graph-bar inverted gray graph-bar-12">
                                        15.4
                                    </div>
                                </div>
                                <img className="chart-image" src={graphFrame}
                                     alt="migraine frequency chart" />
                            </div>
                        </div>
                        <p className="chart-info chart-info--margin">
                            Kuva muokattu lähteestä Ashina M. <em>Migraine. N Engl J Med</em> 2020;383:1866-76. Supplementary Appendix.
                        </p>
                    </div>
                    <ul className="footnote-list">
                        <li className="footnote-list__item">
                            Vasteen saaneiden osuus tarkoittaa niiden potilaiden osuutta, joiden MMD laski ≥50% lähtötilanteesta arviointiaikaan mennessä. †Erenumabi-tulokset ovat kroonisen migreenin ehkäisyä koskevasta II vaiheen tutkimuksesta, koska III vaiheen tutkimuksesta tuloksia ei ole saatavana.
                        </li>
                        <li className="footnote-list__item">
                            <strong>CGRP</strong>, calcitonin gene-related peptide, kalsitoniinigeeniin liittyvä peptidi; <strong>MMD</strong>, monthly migraine day, migreenipäiviä kuukaudessa
                        </li>
                    </ul>
                    <img className="logo" src={logoVyepti}
                         alt="logo vyepti" />
            </div>
        </main>
    )
}

export default Ashina;