import logoVyepti from '../images/vyepti_logo_fix.png';
import efficaciousIcon from '../images/efficacious-background-icon.png';
import graphFrame from '../images/page9/graph-frame_slide18.png'
import React from "react";
import '../css/ResponderRates75.scss';


function ResponderRates75() {
    return (
        <main className="fade-in react-page-9 background">
            <div className="wrapper">
                <img className="background-icon"
                     src={efficaciousIcon}
                     alt="background icon" />
                    <h2 className="title title--margin">
                        Vyepti®-hoitoa saaneet krooniset migreenipotilaat, ≥75 % hoitovaste<sup>2*</sup>
                    </h2>
                    <div>
                        <h3 className="subtitle">≥75 % HOITOVASTEEN SAANEET</h3>
                        <div className="chart-container">
                            <div className="graph">
                                <div className="graph-container inverted">
                                    <div className="graph-bar inverted teal graph-bar-1">
                                        30.9
                                        <div>***</div>
                                    </div>
                                    <div className="graph-bar inverted steal graph-bar-2">
                                        36.9
                                        <div>***</div>
                                    </div>
                                    <div className="graph-bar inverted gray graph-bar-3">
                                        15.6
                                    </div>
                                    <div className="bar-spacer"></div>
                                    <div className="graph-bar inverted teal graph-bar-4">
                                        26.7
                                        <div>***</div>
                                    </div>
                                    <div className="graph-bar inverted steal graph-bar-5">
                                        33.1
                                        <div>***</div>
                                    </div>
                                    <div className="graph-bar inverted gray graph-bar-6">
                                        15.0
                                    </div>
                                </div>
                                <img className="chart-image" src={graphFrame}
                                     alt="migraine frequency chart" />
                            </div>
                            {/*<div className="chart-note chart-note--size">*/}
                            {/*    <img className="chart-note__icon" src={noteIcon}*/}
                            {/*         alt="chart note icon" />*/}
                            {/*        <p className="chart-note__text">*/}
                            {/*            Migreenipäivät vähenivät ensimmäisen kuukauden kuluessa joka kolmannella Vyepti®-potilaalla keskimäärin 16:sta 4:ään*<sup>2</sup>*/}
                            {/*        </p>*/}
                            {/*</div>*/}
                        </div>
                        <p className="chart-info chart-info--margin">
                            Kuva muokattu lähteestä Lipton et al. <em>Neurology</em> 2020;94;e1365-e1377.
                        </p>
                    </div>
                    <ul className="list list--size">
                        <li className="list__item">
Avoimessa tutkimuksessa >80 %:lla Vyepti®-potilaiden toimintakyky parani kliinisesti merkitsevästi 2 vuoden seurannassa<sup>3†</sup>
                        </li>
                    </ul>
                    <ul className="footnote-list">
                        <li className="footnote-list__item">
                            *Lumelääkettä saaneilla vastaava luku oli 16 %. Tulokset perustuvat ≥ 75 %:n migreenivasteeseen (MRR) PROMISE-2-tutkimuksen potilailla.
                            PROMISE-2 tutki kroonista migreeniä sairastavia, joiden lähtötilanteen MMD-keskiarvo (SD) oli 16,1 (4,6).<sup>2</sup>
                            <br></br>
                            †Migreenin vaikutusta
                            arvioitiin käyttämällä MIDAS-kyselylomaketta. American  Headache Societyn mukaan MIDAS-kokonaispistemäärän
                            muutos on kliinisesti merkittävä, jos se laskee ≥ 5 pisteellä (päivällä), kun lähtöarvo on 11–20 päivää, ja ≥ 30 %,
                            kun lähtöarvo on >&nbsp;20 päivää.<sup>2</sup> ***p &lt; 0,0001, sillä Vyepti® 100 mg antoi tulokseksi p = 0,0001 ja Vyepti® 300 mg p &lt; 0,0001 lumeeseen verrattuna<sup>2</sup>.
                        </li>
                        <li className="footnote-list__item">
                            <strong>MIDAS</strong>, migraine disability assessment, migreenin aiheuttamaa haittaa kuvaava indeksi; <strong>MMD</strong>, monthly migraine day, migreenipäiviä kuukaudessa; <strong>MRR</strong>, migraine responder rate, migreenivaste; <strong>SD</strong>, standard deviation, keskihajonta
                        </li>
                    </ul>
                    <img className="logo" src={logoVyepti}
                         alt="logo vyepti" />
            </div>
        </main>
    )
}

export default ResponderRates75;