import React from 'react';
import {Provider} from 'react-redux';
import store from './store';
import App from './App';

function Context(props) {
    return (
        <Provider store={store}>
            <App {...props}/>
        </Provider>
    );
}

export default Context;
