import logoVyepti from '../images/vyepti_logo_fix.png';
import ivGifs from '../images/page4/iv-gifs-slide8-c.gif';
import ivImg from '../images/page4/iv-img.png';
import '../css/IvInfusion.scss';

function IvInfusion() {

    return (
        <main className="fade-in react-page-4 background background--big">
            <div className="wrapper">
                <h2 className="title title--margin">
                    SUUNNITELTU IV-ANNOSTELTAVAKSI, JOLLA VARMISTETAAN TEHOKAS migreenin ESTOHOITO
                </h2>
                <div>
                    <p className="list__title">
                        VYEPTI® ANNOSTELLAAN 30 MINUUTIN LASKIMOINFUUSIOINA 12 VIIKON VÄLEIN<sup>6</sup>
                    </p>
                    <ul className="list list--size">
                        <li className="list__item">
                            IV-annostelu mahdollistaa<sup>6</sup>
                            <ul>
                                <li>100 % hyötyosuuden</li>
                                <li>C<sub>max</sub> heti annostelun jälkeen</li>
                            </ul>
                        </li>
                        <li className="list__item">
                            Hoitosuunnitelman mukainen hoito varmistetaan 3 kuukaudeksi<sup>6</sup>
                        </li>
                        <li className="list__item">
                            Vyepti® on hyvin siedetty, haittavaikutusprofiili on lumelääkeen luokkaa<sup>6</sup>
                        </li>
                        <li className="list__item">Vyepti®-infuusion aikana tai sen jälkeen ei tarvita
                            erityistä seurantaa.<sup>6</sup>*</li>
                    </ul>
                    <div className="iv">
                        <div className="iv-red-line"></div>
                        <img className="iv__gifs" src={ivGifs} alt="IV gifs" />
                        <img className="iv__img" src={ivImg} alt="IV" />
                    </div>
                    <ul className="footnote-list">
                        <li className="footnote-list__item">
                            Suositeltu annos on 100 mg, osa potilaista voi hyötyä 300 mg annoksesta<sup>6</sup> *Hoitavan terveydenhuollon ammattilaisen täytyy tarkkailla tai valvoa potilaita infuusion aikana ja sen jälkeen tavallisen kliinisen käytännön mukaisesti.
                        </li>
                        {/*<li className="footnote-list__item">*/}
                        {/*    <strong>CGRP</strong>, calcitonin gene-related peptide, kalsitoniinigeeniin liittyvä peptidi*/}
                        {/*</li>*/}
                    </ul>
                    <img className="logo" src={logoVyepti}
                         alt="logo vyepti" />
                </div>
            </div>
        </main>
    )
}

export default IvInfusion;
