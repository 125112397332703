import logoVyepti from '../images/vyepti_logo_fix.png';
import impactChart from '../images/page16/impact-chart_slide29_korj_v2.png';
import noteIcon from '../images/page16/note-icon.png';
import React from "react";
import '../css/MohHit.scss';


function MohHit() {
    return (
        <main className="fade-in react-page-16 background">
            <div className="wrapper">
                {/*<img className="background-icon background-icon--size"*/}
                {/*     src={mohIcon} alt="background icon" />*/}
                    <h2 className="title title--margin">
                        Vyepti® parantaa toimintakykyä
                    </h2>
                    <div>
                        <h3 className="subtitle subtitle--margin">
                            Lääkepäänsärkypotilaat, joille päänsärky aiheutti vaikeaa haittaa, HIT-6-kokonaispisteillä arvioituna <sup>10</sup>*
                        </h3>
                        <div className="chart-container">
                            <div className="chart-image">
                                <img src={impactChart} alt="impact of headache chart" />
                            </div>
                            <div className="chart-note chart-note--size">
                                <img className="chart-note__icon" src={noteIcon}
                                     alt="chart note icon" />
                                    <p className="chart-note__text">
                                    Vyepti® paransi kliinisesti merkitsevästi toimintakykyä
                                        ensimmäisen annostelun jälkeen ja vaikutus säilyi yli 6 kuukautta<sup>10†</sup>
                                    </p>
                            </div>
                        </div>
                        <p className="chart-info chart-info--margin">
                            Kuva muokattu lähteestä Silberstein et al. <em>The Journal of Headache and Pain volume 21, Article number: 120</em> (2020) (Supplementary).
                        </p>
                        <ul className="list list--size">
                            <li className="list__item">
                                > 50 % Vyepti®-hoitoa saaneista lääkepäänsärkypotilaista raportoi sairautensa ja MBS-oireittensa lievittyneen paljon tai hyvin paljon<sup>10</sup>
                            </li>
                        </ul>
                        <ul className="footnote-list">
                            <li className="footnote-list__item">
*Niiden potilaiden %-osuus, joiden toimintakykyä migreeni heikensi (HIT-6-kokonaispistemäärä ≥ 60),
                                oli viikkoon 12 mennessä laskenut 51,4 % ja viikkoon 24 mennessä 43,5 %,
                                kun Vyepti®‑annos oli 100 mg, ja vastaavasti 42,9 % ja 39,7 %, kun se oli 300 mg;
                                lumelääkettä saaneilla luvut olivat 60,1 % ja 55,3.<sup>10</sup> †CM-potilaiden hoitovasteen määritelmä on,
                                että HIT-6-kokonaispistemäärä laskee ≥ 6 pisteellä. Tämä CM-spesifinen arvo antaa kliinikoille
                                mahdollisuuden tulkita kliinisesti merkittävää HIT-6-kokonaispistemäärän muutosta ja arvioida hoidosta saatavaa hyötyä.<sup>15</sup> Potilaan toteamat MBS-oireet ovat migreeniin liittyviä oireita, joita potilaat pitävät hankalimpina.<sup>10</sup>
                            </li>
                            <li className="footnote-list__item">
                                <strong>HIT-6</strong>, Headache Impact Test 6 items, 6-kohtainen päänsäryn vaikutusta mittaava testi;
                                <strong> MBS</strong>, most bothersome symptom, haittaavimmat oireet;
                                <strong> MOH</strong>, medication overuse headache, lääkepäänsärky;
                                <strong> CM</strong>, chronic migraine, krooninen migreeni
                            </li>
                        </ul>
                        <img className="logo" src={logoVyepti}
                             alt="logo vyepti" />
                    </div>
            </div>
        </main>
    )
}

export default MohHit;