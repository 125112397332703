import logoVyepti from '../images/vyepti_logo_fix.png';
import medicaments from '../images/page2/medicaments.png'
import calendar from '../images/page2/calendar.gif'
import ribbon from '../images/page2/ribbon_slide5.png'
import lightFlipButton from '../images/light-flip-arrow.png'
import '../css/ImpactedPatient.scss';
import {changeSliderValue, flipCard} from "../actions";
import {useDispatch, useSelector} from "react-redux";
import { Slider } from "react-semantic-ui-range";


function ImpactedPatient() {
    const {sliderValue, cardFlipped} = useSelector(state => state.impactedPatients);
    const dispatch = useDispatch();

    const handleInputChange = (value) => {
        let payload = {
            sliderValue: value
        }
        dispatch(changeSliderValue(payload));
    };
    const handleFlipCard = () => {
        dispatch(flipCard());
    };

    return (
        <main className="fade-in react-page-2 background background--big">
            <div className="wrapper">
                <h2 className="title title--margin">
                    KUINKA MONELLA  POTILAISTASI MIGREENIPÄIVIEN MÄÄRÄ JA KOHTAUSLÄÄKKEIDEN KÄYTTÖ LISÄÄNTYVÄT?
                </h2>
                <div>
                    <div className="calendar-container">
                        <div className={`medicaments-container ${cardFlipped ? 'medicaments hidden' : ''}`}><img className="medicaments"
                                                                    src={medicaments} alt='medicaments' /></div>
                        <div className="card-container">
                            <div className={`card ${cardFlipped ? 'card--extended' : ''}`}>
                                <div className={`card__body ${cardFlipped ? 'card--flipped' : ''}`}>
                                    <div className="card__face">
                                        <div className="card__header bg--steel">
                                            <p className={`card__header-state1 text--light ${sliderValue === 0 ? '' : 'hidden'}`}>Kuinka monena päivänä viikossa potilaasi käyttävät kohtauslääkettä?</p>
                                            <p className={`card__header-state2 text--light ${sliderValue > 0 ? '' : 'hidden'}`}>LÄÄKEPÄÄNSÄRKY (MOH)<sup>12</sup></p>
                                        </div>
                                        <div className="card__calendar-container">
                                            <img className={`card__calendar ${sliderValue === 0 ? '' : 'hidden'}`} src={calendar} alt="calendar gif" />
                                                <div className={`calendar-description text--coral ${sliderValue > 0 ? '' : 'hidden'}`}>
                                                    <p className="calendar-description__title">Triptaanit</p>
                                                    <div className="calendar-description__amount">
                                                        <p className="calendar-description__number calendar-description__first-column">
                                                            <span>&gt; 10</span>
                                                        </p>
                                                        <p className="calendar-description__per-month">/kk</p>
                                                    </div>
                                                    <div className="calendar-description__how-long">
                                                        <div className="calendar-description__first-column"></div>
                                                        <p className="calendar-description__time">≥ 3 kuukautta</p>
                                                    </div>
                                                </div>
                                                <div className={`calendar-description text--red ${sliderValue > 0 ? '' : 'hidden'}`}>
                                                    <p className="calendar-description__title">Särkylääke/NSAID</p>
                                                    <div className="calendar-description__amount">
                                                        <p className="calendar-description__number calendar-description__first-column">
                                                            <span>&gt; 15</span>
                                                        </p>
                                                        <p className="calendar-description__per-month">/kk</p>
                                                    </div>
                                                    <div className="calendar-description__how-long">
                                                        <div className="calendar-description__first-column"></div>
                                                        <p className="calendar-description__time">≥ 3 kuukautta</p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="slider-container">
                                            <label className="slider-text" htmlFor="slider">Kohtauslääkkeen käyttöpäivät viikossa:</label>
                                                <Slider
                                                    color="red"
                                                    inverted={false}
                                                    settings={{
                                                      start: sliderValue,
                                                      min: 0,
                                                      max: 7,
                                                      step: 1,
                                                      onChange: value => {
                                                        handleInputChange(value)
                                                      }
                                                    }}
                                                />

                                                <div className="slider__numbers">
                                                    <p className="slider__number slider__number--teal">0</p>
                                                    <p className="slider__number slider__number--teal">1</p>
                                                    <p className="slider__number slider__number--coral">2</p>
                                                    <p className="slider__number slider__number--coral">3</p>
                                                    <p className="slider__number slider__number--red">4</p>
                                                    <p className="slider__number slider__number--red">5</p>
                                                    <p className="slider__number slider__number--red">6</p>
                                                    <p className="slider__number slider__number--red">7</p>
                                                </div>
                                        </div>
                                        <button onClick={handleFlipCard} className={`flip__btn flip__btn--face flip__btn--padding bg--steel ${sliderValue > 0 ? 'flip__btn--visible' : ''}`}>
                                            <span className="flip__text">Lisätietoa</span>
                                            <img className="flip__icon"
                                                 src={lightFlipButton}
                                                 alt="light flip arrow"/>
                                        </button>
                                    </div>
                                    <div className="card__back bg--light">
                                        <div className="card__header bg--teal">
                                            <p className="card__header-state3 text--light">Kroonisen migreenin ja lääkepäänsäryn noidankehä<sup>9,12-14,16</sup></p>
                                        </div>
                                        <ul className={`card-list text--teal ${cardFlipped ? '' : 'hidden'}`}>
                                            <li className="card-list__item">
                                                Krooninen migreeni alkaa yleensä harvempina  päänsärkyinä,
                                                jotka vähitellen tihenevät ja joihin akuuttilääke tehoaa yhä huonommin<sup>12-14</sup>
                                            </li>
                                            <li className="card-list__item">
                                                Lääkepäänsärky (MOH) on toissijainen  diagnoosi potilailla,
                                                joilla on ennestään primaarinen päänsärkysairaus<sup>12,13</sup>
                                            </li>
                                            <li className="card-list__item">
                                                Lääkepäänsäryn noidankehä olisi katkaistava  akuuttilääkkeiden käyttöä vähentämällä<sup>9,16</sup></li>
                                        </ul>
                                        <div className={`card-ribbon-container  ${cardFlipped ? '' : 'hidden'}`}>
                                            <img className="card-ribbon" src={ribbon} alt="ribbon" />
                                                <p className="chart-info chart-info--size">Laatinut Lundbeck lähteestä Diener H-C et al. Headache. 2021 ja <br></br>
                                                    Diener H-C et al. Eur J Neurol. 2020.
                                                </p>
                                        </div>
                                        <button onClick={handleFlipCard} className={`flip__btn flip__btn--back flip__btn--padding bg--teal ${cardFlipped ? 'flip__btn--visible' : ''}`}>
                                            <span className="flip__text">Palaa</span>
                                            <img className="flip__icon"
                                                 src={lightFlipButton}
                                                 alt="light flip arrow" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img className="logo" src={logoVyepti} alt="logo vyepti" />
            </div>

        </main>
    )
}

export default ImpactedPatient;
