import {useDispatch, useSelector} from "react-redux";
import {closeReferenceModal, openFullReferenceModal} from "../../actions";
import closeIcon from '../../images/teal-close-icon.png'
import ReferenceList from "./ReferenceList";
import backIcon from "../../images/back-icon.png";


function ReferenceModal() {
    const {referenceModalOpen} = useSelector(state => state.main);
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(closeReferenceModal());
    };

    const handleFullReferenceModalOpen = () => {
        dispatch(openFullReferenceModal());
    };

    return (
        <div id="ref-modal" className={`modal modal--ref  ${referenceModalOpen ? 'visible' : ''}`}>
            <div className="modal__card modal__card--ref">
                <button onClick={handleClose} className="modal__close-btn">
                    <img src={closeIcon} alt="teal close icon" />
                </button>
                <button onClick={handleFullReferenceModalOpen} className="back-btn  align-bottom">
                    <img src={backIcon} alt="back icon" />
                </button>
                <div className="modal-container">
                    <div className="modal-heading">
                        <div className="leaf">
                            <h3>Kirjallisuusviitteet</h3>
                        </div>
                    </div>
                    <ReferenceList/>
                </div>
            </div>
        </div>
    )
}

export default ReferenceModal;